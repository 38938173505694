import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useDetectPrinting from './useDetectPrinting';
import {
  Content as ConnectContent,
  Header as ConnectHeader,
  ConnectOmatietovaranto,
  Signin,
  Register,
  SigninOmatietovaranto,
} from './Instructions';
import {
  Content as UploaderContent,
  Header as UploaderHeader,
  UploaderDeviceOptions,
  UploaderDownload,
  UploaderInstallPhase,
  UploaderUnzip,
  UploaderInstall,
  UploaderCheckbox,
  TransferDataView,
  UploaderStartUp,
  UploaderLogin,
  UploaderChooseDevices,
  DeviceTransferGuidesLinks,
  TransferDoneCheckbox,
} from './InstructionsUploader';
import {
  Content as DashboardContent,
  Header as DashboardHeader,
  Continue as DashboardContinue,
  DashboardSharing,
  DashboardSharingAllow,
} from './InstructionsDashboardIntro';
import './instructions.css';

function TableOfContents() {
  return (
    <Fragment>
      <h1>Sensotrend Hoitopolku palvelukokonaisuus</h1>
      <h3>Sisällysluettelo</h3>
      <ul className="toc">
        <li><span><b>Aihe</b></span><span><b>Sivu</b></span></li> <br />
        <li><span>Sensotrend Connect - Käyttöönotto</span><span>2</span></li>
        <li><span>Sensotrend Uploader - Käyttöönotto</span><span>10</span></li>
        <li><span>Sensotrend Uploader - Laitekohtaiset käyttöohjeet</span><span>19</span></li>
        <li><span>Sensotrend Dashboard - Käyttöönotto</span><span>23</span></li>
        <li><span>Kysymys &amp; Vastaus -osio</span><span>29</span></li>
      </ul>
    </Fragment>
  );
}

function InstructionsPrint() {
  const { t } = useTranslation('translations');

  const isPrinting = useDetectPrinting();

  if (isPrinting) {
    // Cannot override body with @media print {body...} , so override it here
    document.body.style.background = 'white';
  } else {
    document.body.style.background = null;
  }

  return (
    <main id="instructions" style={{ '--startCounterPrevValue': 0 }}>
      <TableOfContents /> {/* Manually written TOC, always update this to match generated pdf */}
      <ConnectHeader />
      <ConnectContent isPrinting={isPrinting} />

      <UploaderHeader />
      <UploaderContent isPrinting={isPrinting} />

      <DashboardHeader />
      <DashboardContent isPrinting={isPrinting} />

      {/* Separate QA section here */}
      <section id="QAforPrinting">
        <h1>Kysymys &amp; Vastaus -osio</h1>
        {/* Sensotrend Connect */}
        <span>{t('section')}</span>
          <ConnectOmatietovaranto questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <Signin questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <Register questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <SigninOmatietovaranto questionAnswerOnly isPrinting={isPrinting} />
        {/* Sensotrend Uploader */}
        <span>{t('section')}</span>
          <UploaderDeviceOptions questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderDownload questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderInstallPhase questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderUnzip questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderInstall questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderCheckbox questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <TransferDataView questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderStartUp questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderLogin questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <UploaderChooseDevices questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <DeviceTransferGuidesLinks questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <TransferDoneCheckbox questionAnswerOnly isPrinting={isPrinting} />
        {/* Sensotrend Dashboard */}
        <span>{t('section')}</span>
          <DashboardContinue questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <DashboardSharing questionAnswerOnly isPrinting={isPrinting} />
        <span>{t('section')}</span>
          <DashboardSharingAllow questionAnswerOnly isPrinting={isPrinting} />
      </section>
    </main>
  );
}

export default InstructionsPrint;
