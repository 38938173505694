import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import InstructionBlock from './InstructionBlock';
import './instructions.css';

function InstructionsLinks() {
  return (
    <Fragment>
      <header>
        <h1>Käyttöohjeet</h1>
        <h3>Kooste linkeistä</h3>
      </header>
      <main id="instructions">
      <section>
          <p className="smiley-helper">
            <span className="smiley-helper-face" role="img" aria-label="hymy">😊</span>
            Tässä linkit ohjeisiin, jotka auttavat sinua palveluidemme käytössä.
          </p>
        </section>

        <section>
          <InstructionBlock
            instructionText = {
              <Fragment>
                <b>Laitekohtaiset ohjeet laitteen tietojen purkamiseen Omatietovarantoon</b>
                <ul>
                  <li>
                    <Link to="/instructions/uploader-abbottLibre">Abbott FreeStyle Libre</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-abbottLite">Abbott FreeStyle Lite tai Freedom Lite</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-abbottNeo">Abbott FreeStyle Precision/Optium Neo</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-abbottXtra">Abbott Precision Xtra</Link>
                  </li>
                  <li>
                  <Link to="/instructions/uploader-animas">Animas</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-ascensiaContourNextMeter">Ascensia (Bayer) Contour Next</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-ascensiaContour">Ascensia (Bayer) Contour Next EZ, Contour tai Contour Link</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-caresens">CareSens N Premier tai Dual</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-caresensBt">CareSens N Premier tai Dual (Bletoothia käyttäen)</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-dexcom">Dexcom</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-insuletOmnipod">Insulet OmniPod</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-insuletOmnipodDASH">Insulet OmniPod DASH</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-medtronic">Medtronic 523, 723, Veo tai 530G (using Contour Next Link)</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-medtronic6xG">Medtronic 630G, 640G, 670G (Contour Next Link 2.4 -mittaria käyttäen)</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-oneTouchVerio">OneTouch Verio, Verio Flex ja Verio Reflect</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-oneTouchVerioIQ">OneTouch VerioIQ</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-oneTouchUltraMini">OneTouch UltraMini</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-oneTouchUltra2">OneTouch Ultra 2</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-roche">Roche Accu-Chek Aviva Connect, Guide tai Guide Me</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-tandem">Tandem</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-trivia">Trividia Health True Metrix ja True Metrix Air</Link>
                  </li>
                  <li>
                    <Link to="/instructions/uploader-triviaGo">Trividia Health True Metrix Go</Link>
                  </li>
                </ul>
              </Fragment>
            }
            questionsAndAnswers = {
              {
                'Käyttämälleni laitteelle ei löydy ohjetta':
                  <span>
                    <span role="img" aria-label="hymy">😊</span> Vaikka tuettuja laitteita on paljon, tietojen purkaminen Omatietovarantoon ei vielä onnistu aivan kaikilta laitteilta. Otathan yhteyttä tuotetukeemme sähköpostilla <a href="mailto:tuki@sensotrend.com">tuki@sensotrend.com</a>, jos sinulla on laite, joka puuttuu tältä listalta. Olemme erittäin kiinnostuneita kuulemaan, miltä laitteilta meiltä vielä puuttuu tuki!
                  </span>,
              }
            }
          />
        </section>

        <section>
        <Fragment>
          <b>
            Katso myös ohje Omatietovarantoon tallennettujen tietojen katseluun ja hyödyntämiseen
          </b>
          <ul>
            <li>
              <Link to="/instructions/dashboard">Sensotrend Dashboardissa.</Link>
            </li>
          </ul>
        </Fragment>
        </section>

        <section>
          <p className="smiley-helper">
            <span className="smiley-helper-face" role="img" aria-label="hymy">🤗</span>
            Tietosi ovat nyt hyötykäytössä!
            <br/><br/>
            Isot kiitokset yhteistyöstä ja palveluidemme käytöstä!
          </p>
        </section>

      </main>
    </Fragment>
  );
}

export default InstructionsLinks;
