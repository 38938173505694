import React from 'react';

import './Loading.css';

function LoadingSVG() {
  return (
    <svg id="loading" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200">
      <circle className="bg" cx="100" cy="100" r="50" transform="rotate(-90, 95, 95)" />
      <circle className="animated" cx="100" cy="100" r="50" transform="rotate(-90, 95, 95)" />
    </svg>
  );
}

export default LoadingSVG;
