import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  API, checkForError, dev, getExtendedRequestConfig,
} from './api';
import CallToAction from './CallToAction';
import LoadingSVG from './Loading';

function AcceptToc() {
  const history = useHistory();
  const { t } = useTranslation('');

  const [clickedAccept, setClickedAccept] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function clickAccept(e) {
    e.preventDefault();
    setLoading(true);
    setClickedAccept(true);
    if (dev) {
      history.push('/transfer');
      return;
    }
    fetch(`${API}/user`, getExtendedRequestConfig({
      params: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { acceptedTerms: process.env.REACT_APP_VERSION },
      },
    }))
      .then(checkForError)
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(true);
      })
      .finally(() => {
        history.push('/transfer');
      });
  }

  if (error) {
    return (
      <strong>
        {t('something-went-wrong-please-try-again-later-or-contact-us')} <a href="mailto:info@sensotrend.com">info@sensotrend.com</a>
      </strong>
    );
  }
  if (loading) {
    return (
      <LoadingSVG />
    );
  }
  return (
    <form action={`${API}/user`} method="PUT">
      <input type="hidden" name="acceptedTerms" value={process.env.REACT_APP_VERSION} />
      <section className={clickedAccept ? 'disabled' : ''}>
        <h1>{t('updated-terms-of-use')}</h1>
        <p>
        {t('by-accepting-the-new-terms-of-use-you-ensure-that-the-service-remains-available-to-you-in-the-future-as-well')} </p>
        <ul>
          <li>
            {t('new')} <a target="_blank" href="/dashboard/eula">{t('terms-of-use')}</a>
          </li>
          <li>
            {t('new-0')} <a target="_blank" href="/dashboard/privacy">{t('privacy-statement')}</a>
          </li>
        </ul>
        <p>
        {t('in-addition-you-get-the-option-to-copy')} </p>
        <CallToAction
          disabled={loading || clickedAccept}
          action="/transfer"
          onClick={clickAccept}
        >
          {t('accept')}
        </CallToAction>
        <p>
        {t('if-you-do-not-accept-the-new-terms-of-use')} <a
          href="/dashboard/account#remove">{t('in-the-dashboard-application')}</a>.
        </p>
      </section>
    </form>
  );
}

export default AcceptToc;
