import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from './i18n.js';
import emojiSmilingFace from './images/emojiSmilingFaceWithSmilingEyes.png';
import emojiHuggingFace from './images/emojiHuggingFace.png';
import emojiGrinningSweat from './images/emojiGrinningSweat.png';
import InstructionBlock from './InstructionBlock';
import { uploaderLogin2Image } from './imageLanguageHandler/uploaderLogin2.js';
import { uploaderSelectDeviceImage } from './imageLanguageHandler/uploaderMedtronic6xG1.js';
import { uploaderSendDeviceImage } from './imageLanguageHandler/uploaderMedtronic6xG2.js';
import { uploaderSendDeviceImage2 } from './imageLanguageHandler/uploaderMedtronic6xG22.js';
import { uploaderUploadingImage } from './imageLanguageHandler/uploaderMedtronic6xG3.js';
import { uploaderFinishImage } from './imageLanguageHandler/uploaderMedtronic6xG4.js';
import { uploaderChooseDevicesImage } from './imageLanguageHandler/uploaderMedtronic6xGProblem1.js';
import { uploaderTryAgainImage } from './imageLanguageHandler/uploaderMedtronic6xGProblem2.js';
import { uploaderScrollImage } from './imageLanguageHandler/uploaderScroll.js';
import { uploaderSearchWinImage } from './imageLanguageHandler/uploaderSearchWin.js';
import omatietovarantoImage from './images/omatietovaranto.png';
import medtronic6xG from './images/medtronic6xG.png';
import './instructions.css';

export function UploaderLogin(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            <h4>{t('When launched, the Sensotrend Uploader will open a login page for you if you are not already logged in to our service.')}</h4>
            <p>{t('Enter the email address that serves as your username and the password for Sensotrend services (1) and then press the “Log in” button (2). If you select “Remember me” (3) on this page, you will be logged in automatically in the future.')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('How do I start the Sensotrend Uploader?')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('The Sensotrend Uploader is installed on your computer if you have completed the steps in our installation instructions. If the Sensotrend Uploader is not found on your desktop, you can try to search for it using the search function in the lower left corner of the screen (Windows).')}
                </span>
                <figure>
                  <img
                    src={uploaderSearchWinImage[i18n.language]?.() || uploaderSearchWinImage.en()}
                    alt={t('Search function')}
                  />
                </figure>
              </Fragment>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderLogin2Image[i18n.language]?.() || uploaderLogin2Image.en()}
              alt={t('Sensotrend Uploader, login view')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderChooseDevices(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            <h4>{t('On first use, the Sensotrend Uploader will ask you to select the devices you are using.')}</h4>
            <p>{t('Select')} "Medtronic 630G, 640G, 670G" {t('from the list (1) and press the “Done” button (2).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('I cannot find Medtronic 630G, 640G, 670G (using Contour Next Link 2.4) on the list.')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('The list is so long that it does not fit on the screen at once. You can scroll through the list in the bar on the right side of the view (1).')}
                </span>
                <figure>
                  <img
                    src={uploaderScrollImage[i18n.language]?.() || uploaderScrollImage.en()}
                    alt={t('Searching the right device from the list')}
                  />
                </figure>
              </Fragment>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderSelectDeviceImage[i18n.language]?.() || uploaderSelectDeviceImage.en()}
              alt={t('Device selection view')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderConnectUSB(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            <h4>{t('The Sensotrend Uploader application window should look like this.')}</h4>
            <p>{t('To upload your data, connect')} Contour Next Link 2.4 {t('glucometer into a USB port on your computer, and then do the following.')}</p>
            <p><b>{t('Option A:')}</b> {t('Your insulin pump is NOT CONNECTED to Contour Next Link 2.4 glucometer. Make sure the “Meter and Pump Connected” option is not selected (1), and then enter the serial number on the back of your insulin pump in the “Pump Serial Number” box (2). Select the desired data transfer period (3) and press the “Upload” button (4).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Medtronic 630G, 640G, 670G (using Contour Next Link 2.4) does not appear in the Sensotrend Uploader application window.')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('To display it, select “Choose Devices” (1) from the drop-down menu in the upper right corner, then select from the list')} Medtronic 630G, 640G, 670G {t('(2) and by pressing the “Done” button (3).')}
                </span>
                <figure>
                  <img
                    src={uploaderChooseDevicesImage[i18n.language]?.()
                      || uploaderChooseDevicesImage.en()}
                    style={{ maxWidth: '100%' }}
                    alt={t('Select devices')}
                  />
                </figure>
              </Fragment>,
            [t('I can\'t find the serial number on my insulin pump.')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('Some insulin pumps have a belt clip on the back with a serial number underneath. Refer to the insulin pump manual for instructions on how to remove the belt clip.')}
                </span>
              </Fragment>,
            [t('Unable to upload data. An error message is displayed.')]:
              <Fragment>
                <span className="answers">
                <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('The application cannot connect to your device for some reason. Try reconnecting')} {t('your device')} {t('to your computer and then press the “Try again” button (1). If that doesn’t help either, go through the checklist below.')}
              </span>
              <figure>
                <img
                  src={uploaderTryAgainImage[i18n.language]?.() || uploaderTryAgainImage.en()}
                  alt={t('Retry')}
                />
              </figure>
              <span>
              <p><b>{t('Checklist to diagnose the problem')}</b></p>
                <p>1. {t('Make sure you have installed the latest version of Sensotrend Uploader.')}</p>
                <p>2. {t('Check that')} {t('Contour Next Link 2.4 glucometer')} {t('is charging when it is connected to the cable. If it does not charge, the cable or USB port may be damaged.')}</p>
                <p>3. {t('Disconnect')} {t('Contour Next Link 2.4 glucometer')} {t('from the USB port and reconnect it. Wait for')} {t('Contour Next Link 2.4 glucometer')} {t('to confirm that it is charging before pressing the “Upload” button again.')}</p>
                <p>4. {t('If you are using a USB hub, disconnect it and reconnect it to the computer. Wait for the glucometer to confirm the charge before pressing the “Upload” button again.')}</p>
                <p>5. {t('If you\'re using a Windows computer, make sure you\'ve installed the latest Windows updates, as a lack of updates can prevent your computer from recognizing your device correctly.')}</p>
              </span>
            </Fragment>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderSendDeviceImage[i18n.language]?.() || uploaderSendDeviceImage.en()}
              alt={t('Upload')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderTransfer(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            <p><b>{t('Option B:')}</b> {t('Your insulin pump is CONNECTED to the Contour Next Link 2.4 glucometer. Make sure the “Meter and pump connected” option is selected (1), select the desired data transfer period (2) and press the “Send” button (3).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Medtronic 630G, 640G, 670G (using Contour Next Link 2.4) does not appear in the Sensotrend Uploader application window.')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('To display it, select “Choose Devices” (1) from the drop-down menu in the upper right corner, then select from the list')} Medtronic 630G, 640G, 670G {t('(2) and by pressing the “Done” button (3).')}
                </span>
                <figure>
                  <img
                    src={uploaderChooseDevicesImage[i18n.language]?.()
                      || uploaderChooseDevicesImage.en()}
                    style={{ maxWidth: '100%' }}
                    alt={t('Select devices')}
                  />
                </figure>
              </Fragment>,
            [t('Unable to upload data. An error message is displayed.')]:
              <Fragment>
                <span className="answers">
                <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('The application cannot connect to your device for some reason. Try reconnecting your device to your computer and then press the “Try again” button (1). If this doesn\'t help either, you may want to restart your computer and try again.')}
              </span>
              <figure>
                <img
                  src={uploaderTryAgainImage[i18n.language]?.() || uploaderTryAgainImage.en()}
                  alt={t('Retry')}
                />
              </figure>
            </Fragment>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderSendDeviceImage2[i18n.language]?.() || uploaderSendDeviceImage2.en()}
              alt={t('Upload')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderProgress(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            <h4>{t('After starting the data transfer, the “Upload” button will be replaced by a message (1) indicating the progress of the data transfer.')}</h4>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Data transmission stops and does not appear to continue at all.')]:
              <span className="answers">
                <img src={emojiGrinningSweat} alt="Emoji Grinning Face with Sweat" /> {t('Unfortunately, the data transfer takes a while. So wait for a moment. If the data is not sent in 10 minutes, there is probably something really wrong.')}

              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderUploadingImage[i18n.language]?.() || uploaderUploadingImage.en()}
              alt={t('Uploading')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderApprove(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            {t('If your Contour Next Link 2.4 glucometer is not connected to your insulin pump, you must accept the connection request from your insulin pump with the “Yes” button (1).')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('My insulin pump does not receive a connection request.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('The connection is now not working for some reason. You may want to start over from the beginning of this guide and try again.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img src={medtronic6xG} alt={t('Insulin pump')} />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderComplete(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
      {...props}
        instructionText = {
          <Fragment>
            <h4>{t('When the upload is complete, the Sensotrend Uploader application window will look like this.')}</h4>
            <p>{t('The data has now been uploaded successfully. Transferring a large amount of data takes a while, so in some cases the data sent to it will not appear for half an hour. Once the data has been transferred, you can view it by clicking the “See data” button (1).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('I uploaded my data to the PHR, but it doesn\'t appear there.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="Emoji Smiling Face" /> {t('When there is a lot of data to send, it does not immediately appear in the PHR. You should check back in half an hour. Then they will probably already show up.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderFinishImage[i18n.language]?.() || uploaderFinishImage.en()}
              alt={t('Ready')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function Content(props) {
  return (
    <Fragment>
      <UploaderLogin {...props}/>
      <UploaderChooseDevices {...props} />
      <UploaderConnectUSB {...props} />
      <UploaderTransfer {...props} />
      <UploaderProgress {...props} />
      <UploaderApprove {...props} />
      <UploaderComplete {...props} />
    </Fragment>
  );
}

function InstructionsUploaderMedtronic6xG(props) {
  const { t } = useTranslation('translations');

  const isPrinting = (window.location.search.slice(1) === 'print');
  document.body.style.background = (isPrinting) ? 'white' : null;

  return (
    <Fragment>
      <header>
        <h1>{t('User Instructions')}</h1>
        <h3>Sensotrend Uploader - Medtronic 630G, 640G {t('and')} 670G ({t('Using Contour Next Link 2.4')})</h3>
      </header>
      <main id="instructions" style={{ '--startCounterPrevValue': 0 }}>
        <section>
          <p class="smiley-helper">
            <span class="smiley-helper-face"><img src={emojiHuggingFace} alt="Emoji Hugging Face" /></span>
            {t('Here are instructions for you to upload the data from Medtronic 630G, 640G and 670G insulin pumps to the PHR!')}
          </p>
        </section>

        <Content isPrinting={isPrinting} />

        <section>
          <p class="smiley-helper">
            <span class="smiley-helper-face"><img src={emojiHuggingFace} alt="Emoji Hugging Face" /></span>
            {t('The data has now been successfully uploaded! You can view them with Sensotrend Dashboard application or directly from the user interface in the PHR.')}
          </p>
          <h4>{t('Instructions and tips for viewing and utilizing your information with Sensotrend Dashboard application can be found from this')} <a href="https://www.sensotrend.fi/dashboard/helpIntro"> {t('instruction.')}</a></h4>
          <p />
          <h4>{t('LocalPHRInstruction-BoldText')}</h4>
          <p>
          {t('LocalPHRInstruction-TextPart1')} <a href={t('LocalPHRInstruction-Link')} rel="noopener noreferrer" target="_blank">{t('LocalPHRInstruction-Link')}</a> {t('LocalPHRInstruction-TextPart2')}
          </p>
          <figure>
            <img src={omatietovarantoImage} alt={t('LocalPHRInstruction-Alt')} />
          </figure>
        </section>
      {isPrinting
        /* Separate QA section here */
        ? <section id="QAforPrinting">
          <Fragment>
            <h1>{t('Questions and answers')}</h1>
            <span>{t('section')}</span>
            <UploaderLogin questionAnswerOnly isPrinting />
            <span>{t('section')}</span>
            <UploaderChooseDevices questionAnswerOnly isPrinting />
            <span>{t('section')}</span>
            <UploaderConnectUSB questionAnswerOnly isPrinting />
            <span>{t('section')}</span>
            <UploaderTransfer questionAnswerOnly isPrinting />
            <span>{t('section')}</span>
            <UploaderProgress questionAnswerOnly isPrinting />
            <span>{t('section')}</span>
            <UploaderApprove questionAnswerOnly isPrinting />
            <span>{t('section')}</span>
            <UploaderComplete questionAnswerOnly isPrinting />
          </Fragment>
        </section>
        : null
        }
      </main>
    </Fragment>
  );
}

export default InstructionsUploaderMedtronic6xG;
