import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('translations');

  return (
    <footer>
      <div>
        <Link className="inline" to="/">
          Sensotrend Connect
        </Link>{' '}
        v{process.env.REACT_APP_VERSION}
      </div>
      <div>
        &copy;2020+{' '}
        <a className="inline" href="/">
          Sensotrend Oy
        </a>
      </div>
      <div>
        <span>
          <Link className="inline" to="/eula">
            {t('Terms of use') /* FIXME: or is it EULA? */}
          </Link>
        </span>
        <span className="separator">&middot;</span>
        <span>
          <Link className="inline" to="/privacy">
            {t('Privacy')}
          </Link>
        </span>
        <span className="separator">&middot;</span>
        <span>
          <Link className="inline" to="/instructions">
            {t('Instructions')}
          </Link>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
