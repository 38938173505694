import React, { useState } from 'react';

const UserSettingsContext = React.createContext({});

export function UserSettingsContextProvider({ children }) {
  const [userSettings, setUserSettings] = useState({});

  const state = {
    userSettings,
    setUserSettings,
  };

  return (
    <UserSettingsContext.Provider value={state}>
      {children}
    </UserSettingsContext.Provider>
  );
}

export default UserSettingsContext;
