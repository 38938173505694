import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

function InstructionBlock({
  instructionText, questionsAndAnswers, questionAnswerOnly = false,
  isPrinting = false, restContent = null, QATitle = null,
}) {
  const { t } = useTranslation('translations');
  const [showHelp, setShowHelp] = useState(questionAnswerOnly);
  const onClick = () => setShowHelp(!showHelp);

  const detailsSummarySupported = (typeof HTMLDetailsElement !== 'undefined');
  return (
    <Fragment>
      { questionAnswerOnly // If only QA, show title text, otherwise null. NOT USED (yet).
        ? QATitle
        : null
      }
      { // If this is InstructionBlock is not QA-only, show instruction text as well.
        !questionAnswerOnly
          ? <div className="instructionTextContainer">
            { !isPrinting
              ? (
                <span
                  onClick={onClick}
                  className="question-helper"
                  role="img"
                aria-label="Kysyttävää">🙋❔</span>
              )
              : null
            }
            {instructionText}
          </div>
          : null
      }
      { // If printing, refer to the end of the document (pdf or print)
        // eslint-disable-next-line no-nested-ternary
        (!questionAnswerOnly && isPrinting)
          ? (
            <p
              style={{ fontSize: '0.8em', color: 'gray', fontStyle: 'italic' }}
            >
              {t('Problems? See questions and answers at the end of the instructions ')}
              ({t('section ')}<span className="fillMe" />)
            </p>
          )
          : (
            showHelp // If not printing, and showHelp has been toggled, show help box
              ? <div className="question-helper-details">
                  { Object.entries(questionsAndAnswers)
                    .map(([key, value]) => (
                      <Fragment>
                        { detailsSummarySupported
                          ? <details key={key} open={questionAnswerOnly} >
                            <summary>
                              {key}
                            </summary>
                            {value}
                          </details>
                          : <Fragment>
                              <div className="polyfill-summary">
                                {key}
                              </div>
                              <div className="polyfill-details">
                                {value}
                              </div>
                            </Fragment>
                        }
                      </Fragment>
                    ))
                  }
              </div>
              : null // if not printing, and showHelp hasn't been toggled
          )
      }
      { !questionAnswerOnly
        ? restContent
        : null
      }

    </Fragment>
  );
}

export default InstructionBlock;
