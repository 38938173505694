import React, { Fragment, useEffect, useState } from 'react';
import {
  Link, Redirect, useHistory, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { dev } from './api';
import uploaderImage from './images/sensotrend_uploader_startup.png';
import './downloads.css';
import './form.css';

function Download() {
  const { t } = useTranslation('translations');

  const history = useHistory();
  const { os } = useParams();
  const downloadFile = `/download/uploader?os=${os}`;

  const [installed, setInstalled] = useState(false);

  useEffect(() => {
    if (!dev) {
      window.location = downloadFile;
    }
  }, [downloadFile]);

  if (!os) {
    return <Redirect to="/uploader" />;
  }

  function processForm(event) {
    event.preventDefault();
    history.push('/upload');
  }

  return (
    <Fragment>
      <header>
        <h1>{t('Install Uploader!')}</h1>
      </header>
      <main id="download">
        <section>
          <p>
            <strong>{t('The installation file should now be downloading to your computer.')}</strong>
          </p>
          <p>
            {`${t('If the download didn\'t start, download the file')} `}
            <a download href={downloadFile}>
              {t('from here')}
            </a>
            .
          </p>
          <p>
            {`${t('If you need help with installing the application, read')} `}
            <strong>
              <Link to="/instructions/installation">{t('detailed installation instructions')}</Link>
            </strong>
            .
          </p>
        </section>
        <section>
          <figure>
            <img src={uploaderImage} alt={t('Screenshot of the Sensotrend Uploader application')}></img>
            <figcaption>{t('The installed file should look something like this.')}</figcaption>
          </figure>
        </section>
        <section>
          <form onSubmit={processForm}>
            <p>
              <strong>
                {t('Once you have installed the application, you are able to transfer your data to Kanta PHR.')}
              </strong>
            </p>
            <div>
              <input
                id="installed"
                type="checkbox"
                name="installed"
                title={t('Tick this once you have Sensotrend Uploader application installed on your computer')}
                checked={installed}
                onChange={(event) => setInstalled(event.target.checked)}
              />
              <label htmlFor="installed">{t('I have installed the Sensotrend Uploader application')}</label>
            </div>
            <input
              type="submit"
              title={t('Go to Sensotrend Dashboard application')}
              disabled={!installed}
              value={t('Transfer data!')}
            />
          </form>
        </section>
      </main>
    </Fragment>
  );
}

export default Download;
