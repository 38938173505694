import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CallToAction from './CallToAction';

function GoodBye2() {
  const { t } = useTranslation('');
  return (
    <Fragment>
      <header>
        <h1>
          {t('Data transfer between Sensotrend services and Omatietovaranto will end on 30.11.2023')}
        </h1>
      </header>
      <main id="terms-update">
        <small>{t('We will update this page as the case progresses.')}</small>
        <p>
          <small>
            <strong>{t('updated-on-diabetes-week-19-11-2023')}</strong>
          </small>
        </p>
        <h2>{t('terms-of-use-will-be-updated')}</h2>
        <p>
          {t('the-use-of-omatietovaranto-of-kanta-system')} </p>
        <p>
          {t('the-only-essential-change-in-the-terms-of-use-is')} </p>
        <p>
          {t('this-currently-applies-only-to-data-saved-to-the-service')} </p>
        <p>
          {t('none-of-your-data-will-be-lost-during-the-change')} </p>
        <p>
          {t('to-continue-using-the-service')} </p>
        <CallToAction action={'/terms'}>{t('lets-start')}</CallToAction>
        <p>
          {t('you-can-also-read')} <Link to="/goodbye-1">{t('the-previous-post')}</Link> {t('and')} <Link to="/goodbye-3">{t('a-later-update')}</Link> {t('on the subject')}.
        </p>
        <p>
          {t('We welcome any thoughts and concerns you may have about this change. Please contact us at')} <a
            href="mailto:info@sensotrend.com">info@sensotrend.com</a>.
        </p>
        <p className="signature">
          {t('Best regards')}<br />
          <i>- {t('The Sensotrend team')}</i>
        </p>
        <h3 id="more-info">{t('For more information:')}</h3>
        <ul>
          <li>
            {t('new-privacy-statement')} <a
              href="https://sensotrend.com/dashboard/privacy">https://sensotrend.com/dashboard/privacy</a>
          </li>
          <li>
            {t('new-terms-of-use')} <a
              href="https://sensotrend.com/dashboard/eula">https://sensotrend.com/dashboard/eula</a>
          </li>
          <li>
            {t('More information on the Nightscout.fi service:')}  <a
              href="https://nightscout.fi/shutdown">https://nightscout.fi/shutdown</a>
          </li>
        </ul>
      </main>
    </Fragment>
  );
}

export default GoodBye2;
