import imageDe from '../images/de/uploader-ascensiaContourNextMeter-2.png';
import imageEn from '../images/en/uploader-ascensiaContourNextMeter-2.png';
import imageEs from '../images/es/uploader-ascensiaContourNextMeter-2.png';
import imageFi from '../images/fi/uploader-ascensiaContourNextMeter-2.png';
import imageFr from '../images/fr/uploader-ascensiaContourNextMeter-2.png';
import imageIt from '../images/it/uploader-ascensiaContourNextMeter-2.png';
import imageNo from '../images/no/uploader-ascensiaContourNextMeter-2.png';
import imageSv from '../images/sv/uploader-ascensiaContourNextMeter-2.png';

export const uploaderSendDeviceImage = {
  de() {
    return imageDe;
  },
  en() {
    return imageEn;
  },
  es() {
    return imageEs;
  },
  fi() {
    return imageFi;
  },
  fr() {
    return imageFr;
  },
  it() {
    return imageIt;
  },
  no() {
    return imageNo;
  },
  sv() {
    return imageSv;
  },
};
