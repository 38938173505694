import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18n from './i18n.js';
import CallToAction from './CallToAction';
import InstructionBlock from './InstructionBlock';
import emojiSmilingFace from './images/emojiSmilingFaceWithSmilingEyes.png';
import emojiGrinningFace from './images/emojiGrinningFace.png';
import emojiThinkingFace from './images/emojiThinkingFace.png';
import { selectDevicesImage } from './imageLanguageHandler/selectDevices.js';
import { selectUploaderImage } from './imageLanguageHandler/selectUploader.js';
import { openDownloadedImage } from './imageLanguageHandler/openDownloaded.js';
import { installUploader1Image } from './imageLanguageHandler/installUploader1.js';
import { installUploader2Image } from './imageLanguageHandler/installUploader2.js';
import { uploadData1Image } from './imageLanguageHandler/uploadData1.js';
import { uploadData2Image } from './imageLanguageHandler/uploadData2.js';
import { tidepoolInstallImage } from './imageLanguageHandler/tidepoolInstall.js';
import { uploaderLoginImage } from './imageLanguageHandler/uploaderLogin.js';
import { uploaderForgottenPasswordImage } from './imageLanguageHandler/uploaderForgottenPassword.js';
import { uploaderSelectDevicesImage } from './imageLanguageHandler/uploaderSelectDevices.js';
import { uploaderScrollImage } from './imageLanguageHandler/uploaderScroll.js';
import { uploaderLocationImage } from './imageLanguageHandler/uploaderLocation.js';
import { uploaderSearchWinImage } from './imageLanguageHandler/uploaderSearchWin.js';
import './instructions.css';

export function Header() {
  const { t } = useTranslation('translations');

  return (
    <header>
      <h1>{t('Deployment instructions')}</h1>
      <h3>{t('Transfer of data to the PHR')}</h3>
    </header>
  );
}

export function UploaderDeviceOptions(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="DeviceOptions">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('You will now be automatically directed to the installation of the Sensotrend Uploader.')}</h4>
            <p>{t('Select the devices you are using (1) and then press the “OK” button (2). You can search for devices with the “Search” function (3).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Why is the list so long?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Yes, the list is long. Our services support numerous devices used by diabetics, whose data can be transferred to the PHR through a number of different applications. The list is long so that the device you are using can be found in the list.')}
              </span>,
            [t('I can\'t find the device I\'m using in the list.')]:
              <span className="answers">
                <img src={emojiThinkingFace} alt="" /> {t('Have you already tried the “Search” function (3)? If you have a device that is not listed, please contact our product support via email')} <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a>{t('. We are interested to hear which devices still lack support.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={selectDevicesImage[i18n.language]?.() || selectDevicesImage.en()}
              style={{ maxWidth: '50%' }}
              alt={t('Application installation')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderDownload(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderDownload">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('The details of the next screen depend on the device selections you make.')}</h4>
            <p>{t('Press the “Download Sensotrend Uploader!” button (1). The “Sensotrend Uploader” window will then open, with a green upload button for the application version that is most likely to work for you. Now press the charge button (2).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('What are the other options? What is Nightscout?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Nightscout is an open source development community that makes products to make life easier for diabetics. If you already use Nightscout applications, you can also transfer the information collected by these applications to the PHR with our applications.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={selectUploaderImage[i18n.language]?.() || selectUploaderImage.en()}
              style={{ maxWidth: '100%' }}
              alt={t('Application installation')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderInstallPhase(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderInstallPhase">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('Next, you will be prompted to install the Sensotrend Uploader.')}</h4>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Why do I need the Sensotrend Uploader? What can it be used for?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Sensotrend Uploader is a computer application that allows you to transfer your data from the devices you use to the PHR.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={installUploader1Image[i18n.language]?.() || installUploader1Image.en()}
              alt={t('Application installation')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderUnzip(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderUnzip">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
          <h4>{t('The installer will download to your computer.')}</h4> {t('The installation depends a bit on your operating system. This guide follows the Windows operating system.')}
          <p>{t('Follow these instructions to start the Sensotrend Uploader installation.')}</p>
          <p>{t('When the download is complete, the downloaded file is usually found in the lower left corner of your browser. Perform it with the “Open” function (1).')}</p>
        </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Do I need a Windows computer?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Sensotrend Uploader also works on Mac and Linux computers. On these operating systems, extracting the installation file differs slightly from the Windows operating system illustrations shown here, but works according to the same principle.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={openDownloadedImage[i18n.language]?.() || openDownloadedImage.en()}
              style={{ maxWidth: '50%' }}
              alt={t('Download the application')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderInstall(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderInstall">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('Follow the instructions in the installer to install the Sensotrend Uploader.')}</h4>
            <p>{t('The application uses Tidepool drivers, which are installed during installation. Therefore, the messages below will be displayed during installation.')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('The installer asks for permission to make changes to my computer.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('This is perfectly normal if you are not logged in with administrator credentials. You can give permission without worry.')}
              </span>,
            [t('I do not have permission to install applications on my computer.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Ask the computer support or another person with administrator privileges on your computer to install the program.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={tidepoolInstallImage[i18n.language]?.() || tidepoolInstallImage.en()}
              style={{ maxWidth: '100%' }}
              alt={t('Install the application')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderCheckbox(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploadeCheckbox">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('The end of the window where you were asked to install the Sensotrend Uploader looks like this.')}</h4> {t('Check the “I have installed the Sensotrend Uploader” box (1) and continue with the “Transfer data!” button (2).')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('I can\'t find this selection.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('In step 9 you were asked to install the Sensotrend Uploader. This view is from the bottom of its window. The window should be open in your internet browser if you have not closed it after step 9.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={installUploader2Image[i18n.language]?.() || installUploader2Image.en()}
              alt={t('Application installed')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function TransferDataView(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="TransferDataView">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('Next you will see a window like this.')}</h4>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('What data should I upload')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('This refers to data collected by the devices you use (insulin pump, glucometer, etc.) that you can upload to the PHR with the Sensotrend Uploader you just installed.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploadData1Image[i18n.language]?.() || uploadData1Image.en()}
              alt={t('Install the application')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderStartUp(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderStartUp">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('Now launch the Sensotrend Uploader you just installed.')}</h4> {t('Depending on the installation you have performed, the application can be found, for example, on the Desktop (1) or in the application list (2).')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('I can\'t find the Sensotrend Uploader.')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="" /> {t('For example, you can try searching for an application using the Search function (Windows) in the lower-left corner of the screen.')}
                </span>
                <figure>
                  <img
                    src={uploaderSearchWinImage[i18n.language]?.() || uploaderSearchWinImage.en()}
                    alt={t('Find the app.')}
                  />
                </figure>
              </Fragment>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderLocationImage[i18n.language]?.() || uploaderLocationImage.en()}
              style={{ maxWidth: '40%' }}
              alt={t('Sensotrend Uploader application')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderLogin(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderLogin">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('The application will ask for your email address and password (1).')}</h4> {t('Enter')} <b>{t('the same email address and password you entered earlier')}</b> {t(', then press the “Log in” button (2).')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('I don\'t remember my password for Sensotrend services.')]:
              <Fragment>
                <span className="answers">
                  <img src={emojiSmilingFace} alt="" /> {t('No worries. There is a link (1) in the application login window that allows you to set a new password.')}
                </span>
                <figure>
                  <img
                    src={uploaderForgottenPasswordImage[i18n.language]?.()
                      || uploaderForgottenPasswordImage.en()}
                    alt={t('Sensotrend Uploader, forgotten password.')} />
                </figure>
              </Fragment>,
            [t('I can\'t log in even though I enter my email address and password.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Your username is the e-mail address with which you have registered as a user of Sensotrend services. The password is a password of your choice that you have entered to register as a user of the Services. Try entering this username and password. If you do not remember your password, you can reset it as described in the previous section.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderLoginImage[i18n.language]?.() || uploaderLoginImage.en()}
              alt={t('Sensotrend Uploader application')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function UploaderChooseDevices(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="UploaderChooseDevices">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('The first time you use it, you will be asked to select the devices you are using.')}</h4> {t('Select the devices you are using from the list (1) and then press the “Done” button (2).')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('The device I am using cannot be found in the list.')]:
            <Fragment>
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('Not all supported devices can fit on the screen at once. To see more devices, scroll to the bar on the right side of the window (1).')}
              </span>
              <figure>
                <img
                  src={uploaderScrollImage[i18n.language]?.() || uploaderScrollImage.en()}
                  alt={t('Select the devices you are using from the options')}
                />
              </figure>
            </Fragment>,
            [t('I have both an insulin pump and a glucose sensor. How do I continue?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('You can select as many devices as you want from the list. In this case, they will appear in the Sensotrend Uploader one below another so that each device has its own “Upload” button.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploaderSelectDevicesImage[i18n.language]?.() || uploaderSelectDevicesImage.en()}
              alt={t('Select the devices you are using from the options')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function SmileyBeginTransfer() {
  const { t } = useTranslation('translations');

  return (
    <section id="SmileyBeginTransfer">
      <p class="smiley-helper">
        <span class="smiley-helper-face"><img src={emojiSmilingFace} alt="" /></span>
        {t('You are now ready to extract the data stored on your devices to the PHR!')}
      </p>
    </section>
  );
}

export function DeviceTransferGuidesLinks(props) {
  const { t } = useTranslation('translations');

  const { isPrinting } = props;
  return (
    <section id="DeviceTransferGuidesLinks">
      <InstructionBlock
          {...props}
          instructionText = {
              <h4>{t('Device-specific instructions for extracting device data to the PHR')}</h4>
          }
          questionsAndAnswers = {
            {
              [t('No instructions can be found for the device I am using.')]:
                <span className="answers">
                  <img src={emojiSmilingFace} alt="" /> {t('Although there are many supported devices, it is not yet possible to extract data from all devices. Please contact our product support by email')} <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a>{t(', if you have a device that is missing from this list. We are very interested to hear which devices we still lack support for!')}
                </span>,
            }
          }
          restContent = {
            <ul>
              <li>
                <Link to="/instructions/uploader-abbottLibre">Abbott FreeStyle Libre {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-abbottLibre' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-abbottLite">Abbott FreeStyle Lite {t('or')} Freedom Lite {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-abbottLite' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-abbottNeo">Abbott FreeStyle Precision/Optium Neo {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-abbottNeo' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-abbottXtra">Abbott Precision Xtra {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-abbottXtra' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-animas">Animas {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-animas' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-ascensiaContourNextMeter">Ascensia (Bayer) Contour Next {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-ascensiaContourNextMeter' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-ascensiaContour">Ascensia (Bayer) Contour Next EZ, Contour, Contour Link {t('or')} Contour Plus {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-ascensiaContour' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-caresens">CareSens N Premier {t('or')} Dual {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-caresens' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-caresensBt">CareSens N Premier {t('or')} Dual ({t('Using Bluetooth')}) {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-caresensBt' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-dexcom">Dexcom {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-dexcom' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-insuletOmnipod">Insulet OmniPod {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-insuletOmnipod' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-insuletOmnipodDASH">Insulet OmniPod DASH {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-insuletOmnipodDASH' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-medtronic">Medtronic 523, 723, Veo {t('or')} 530G ({t('Using Contour Next Link')}) {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-medtronic' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-medtronic6xG">Medtronic 630G, 640G, 670G ({t('Using Contour Next Link 2.4')}) {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-medtronic6xG' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-oneTouchVerio">OneTouch Verio, Verio Flex {t('or')} Verio Reflect {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-oneTouchVerio' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-oneTouchVerioIQ">OneTouch VerioIQ {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-oneTouchVerioIQ' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-oneTouchUltraMini">OneTouch UltraMini {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-oneTouchUltraMini' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-oneTouchUltra2">OneTouch Ultra 2 {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-oneTouchUltra2' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-reliOn">ReliOn Premier (BLU, Voice {t('or')} Classic) {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-reliOn' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-roche">Roche Accu-Chek Aviva Connect, Guide {t('or')} Guide Me {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-roche' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-tandem">Tandem {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-tandem' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-trivia">Trividia Health True Metrix {t('or')} True Metrix Air {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-trivia' : null}</Link>
              </li>
              <li>
                <Link to="/instructions/uploader-triviaGo">Trividia Health True Metrix Go {isPrinting ? 'https://www.sensotrend.fi/connect/instructions/uploader-triviaGo' : null}</Link>
              </li>
            </ul>
          }
        />
      </section>
  );
}

export function TransferDoneCheckbox(props) {
  const { t } = useTranslation('translations');

  return (
    <section id="TransferDoneCheckbox">
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('You have now transferred the data from your devices to the PHR.')}</h4>
            <p>{t('Next, check the “I have transferred data to PHR” box (1) and continue with “Forward!” button (2).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('I cannot see this window.')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('In step 12 you were asked to upload your data. The window should be open in your internet browser if you have not closed it after step 12.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={uploadData2Image[i18n.language]?.() || uploadData2Image.en()}
              alt={t('Install the application')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function SmileyReadyToViewData() {
  const { t } = useTranslation('translations');

  return (
    <section id="SmileyReadyToViewData">
      <p class="smiley-helper">
        <span class="smiley-helper-face"><img src={emojiGrinningFace} alt="" /></span>
        {t('Your data is now in the PHR and you can start viewing it.')}
      </p>
    </section>
  );
}

export function Content(props) {
  return (
    <Fragment>
      <UploaderDeviceOptions {...props} />
      <UploaderDownload {...props} />
      <UploaderInstallPhase {...props} />
      <UploaderUnzip {...props} />
      <UploaderInstall {...props} />
      <UploaderCheckbox {...props} />
      <TransferDataView {...props} />
      <UploaderStartUp {...props} />
      <UploaderLogin {...props} />
      <UploaderChooseDevices {...props} />
      <SmileyBeginTransfer {...props} />
      <DeviceTransferGuidesLinks {...props} />
      <TransferDoneCheckbox {...props} />
      <SmileyReadyToViewData {...props} />
    </Fragment>
  );
}

function InstructionsUploader() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <Header />
      <main id="instructions" style={{ '--startCounterPrevValue': 5 }}>
        <Content />
        <CallToAction action="/instructions/sensotrend-connect">&larr; {t('Previous step')}</CallToAction>
        <CallToAction action="/instructions/sensotrend-dashboard-intro" style={{ float: 'right' }}>{t('Next step')} &rarr;</CallToAction>
      </main>
    </Fragment>
  );
}

export default InstructionsUploader;
