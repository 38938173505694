import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ceImage from './images/ce.svg';
import eInstruction from './images/e-instruction.png';
import manufacturerImage from './images/manufacturer.png';
import manufacturingDate from './images/manufacturingDate.png';
import udi from './images/udi.png';

import BuildTime from './BuildTime';
import './instructions.css';
import './index.css';

function InstructionsLanding() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>{t('Instructions')}</h1>
      </header>
      <main id="instructions">
        <section id="intendeduse">
          {/* This section includes the "intended target groups with clear indications".
              Ref.
                - CLIN-T2-PL Clinical Evaluation Plan, clause 4.1, and
                - MDR annex XIV, clause 1, 3rd bullet, and annex I, clause 23.4, subclause b).
          */}
          <h2>{t('Safety Information')}</h2>
          {/* Language reguirements are coming from finnish law 629/2010, 12 § */}
          <small><Link to={t('Link-LocalAdditionalSafetyInfoRequired')}>{t('LinkText-LocalAdditionalSafetyInfoRequired')}</Link></small>
          <h3>{t('Indications for Use')}</h3>
          <p>
            {t('Sensotrend Connect is an application that supports the treatment of diabetes by transferring data collected by blood glucose meters, glucose sensors and insulin pumps, and similar devices from other applications and cloud services to healthcare information systems.')}
          </p>
          <p>
            {t('The application is intended for all user groups whose data transfer is permitted and enabled by data sources and healthcare information systems supported by Sensotrend Connect.')}
          </p>
        </section>
        <section id="contraindications">
          {/* This section includes the "contra-indications".
              Ref.
                - CLIN-T2-PL Clinical Evaluation Plan, clause 4.1, and
                - MDR annex XIV, clause 1, 3rd bullet, and annex I, clause 23.4, subclause b).
          */}
          <h3>{t('Contraindications')}</h3>
          <p>
            {/* This clause included the risk control action for R15.
                Ref. Risk Management File - Medical issues (RISK-T5-MF)
            */}
            {t('The application is not intended for the transfer of data used for real-time monitoring of health status.')}
          </p>
          <p>
            {/* This clause included the risk control action for R5.
                Ref. Risk Management File - Medical issues (RISK-T5-MF)
            */}
            {t('The measurement data transmitted by the application is only suitable for monitoring your health when your measuring device has been calibrated according to the measuring device manufacturer\'s instructions.')}
          </p>
          <p>
            {/* This clause is for MDR, annex I, clause 23.4, subclause w). */}
            {t('The use of the application does not replace the care provided by a healthcare professional, but complements it. If there are significant changes in your glucose balance, contact your healthcare provider.')}
          </p>
          <p>
            {/* This clause included the risk control action for R1, R11, R16, R19 and R22.
                Ref. Risk Management File - Medical issues (RISK-T5-MF)
            */}
            {t('Do not make significant changes to your treatment based on data transferred by Sensotrend Connect alone. Before making a treatment decision, the data transferred by Sensotrend Connect should be compared with the data from the original data source to ensure that the entire data transfer chain has functioned reliably.')}
          </p>
          <p>
            {/* This clause is for MDR, annex I, clause 23.4 subclause z). */}
            {t('If you encounter serious incidents while using the application, you should notify our customer support at')} <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a> {t('and the competent authority.')}
          </p>
          <h3>{t('Information related to System Requirements')}</h3>
          <p>
            {/* This clause is for MDR, annex I, clause 23.4, subclause ab). */}
            {t('Sensotrend Connect is a service that does not have specific system requirements for user equipment. The application\'s user interface is designed to work with the most widely used Internet browsers. However, not all browsers on the market can be verified individually. If you notice incorrect, unexpected or incomplete activity, please contact our customer support by')} <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a>{t(' so that we can resolve and, to the best of our ability, correct the problem.')}
          </p>
          <p>
            {t('Do not use the application to transfer data used to monitor your health and balance of care if it appears to be malfunctioning.')}
          </p>
        </section>
        <section id="connect">
          <h2>{t('Deployment')}</h2>
          <p>
            {t('Read the detailed')} <Link to="/instructions/sensotrend-connect"
            >{t('deployment instructions')}</Link> {t('to deploying the service.')}
          </p>
        </section>
        <section id="upload">
          <h2>{t('Data Transfer')}</h2>
          <p>
          {t('You can transfer data using Sensotrend Uploader. Look at')} <Link
            to="/instructions/uploaders">{t('device-specific data transfer instructions')}</Link>.
          </p>
        </section>
        <section id="dashboard">
          <h2>{t('Viewing the Data')}</h2>
          <p>
          {t('You can view the data stored in the PHR, for example by ')} <a
            href="/dashboard">{t('Sensotrend Dashboard -application')}</a>{t('. Look at')} <a
              href="https://www.sensotrend.fi/dashboard/helpIntro"> {t('instructions for viewing the data')}</a>.
          </p>
        </section>
        <section id="label">
          {/* This section includes the label.
              Ref. MDR annex XIV, clause 1, 3rd bullet, and annex I, clause 23.2.
          */}
          <h2>{t('Product Information')}</h2>

          {/* This clause is for MDR, annex I, clause 23.2, subclauses a) and b). */}
          <p>Sensotrend Connect v{process.env.REACT_APP_VERSION}</p>

          {/* This clause is for MDR, annex I, clause 23.2, subclause h). */}
          <img
            className="labeling"
            src={udi}
            alt={t('UDI')}
          />
          <p>D-FIMF000000500SC000001F9<br /><br /></p>

          <img class="labeling" src={ceImage} alt="CE" />
          {/* This clause is for MDR, annex I, clause 23.2, subclause q). */}
          <p>
            {t('Sensotrend Connect is a class I medical device.')}<br />
            {t('See ')}
            <a
              href={`${navigator.userAgent === 'ReactSnap'
                ? 'https://www.sensotrend.fi/connect'
                : process.env.PUBLIC_URL
              }/DECO-T5-DC-MDD_EU_Declaration_of_Conformity_-_Sensotrend_Connect_-_signed.pdf`}
              rel="noopener noreferrer" target="_blank">{t('Declaration of Conformity')}</a>.
          </p>

          <img
            className="labeling"
            src={eInstruction}
            alt={t('Electronic instruction')}
          />
          {/* This clause is for EU regulation for electronic instructions (2021/2226), article 6,
              clause 1 and 3d.
           */
          }
          <p>
            {t('This instruction for use is supplied in electronic form only. You can request a paper version from ')}
            <a href={`mailto:${t('support@sensotrend.com')}`}>{t('support@sensotrend.com')}</a>
            {t('. The paper version will be delivered within 7 calendar days of receiving the request.')}
          </p>

          {/* Date of manufacture is for MDR, annex I, clause 23.2, subclause j).
            Date format is specified in ISO 1041:2008 & A1:2013, clause 4.4.
          */}
          <img
            className="labeling"
            src={manufacturingDate}
            alt={t('Date of manufacture')}
          />
          <p>{BuildTime}</p>
          <br />

          <img
            className="labeling"
            src={manufacturerImage}
            alt={t('Manufacturer')}
          />
          {/* This clause is for MDR, annex I, clause 23.2, subclause c). */}
          <address>
            Sensotrend Oy<br />
            {/* Address is specified in ISO 1041:2008 & A1:2013, clause 5.1.2. */}
            Tampellan esplanadi 19 A 55<br />
            33180 Tampere{t(', Finland')}<br />
            <br />
            <a href={`mailto:${t('support@sensotrend.com')}`}>{t('support@sensotrend.com')}</a>
            <br />
          </address>
        </section>
      </main>
    </Fragment >
  );
}

export default InstructionsLanding;
