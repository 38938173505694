import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import CallToAction from './CallToAction';

function Registered() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>{t('We are ready!')}</h1>
      </header>
      <main>
        <p>{t('Your account has now been registered.')}</p>
        <CallToAction action="/kanta">{t('Onward!')}</CallToAction>
      </main>
    </Fragment>
  );
}

export default Registered;
