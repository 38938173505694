import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  API, checkForError, defaultRequestConfig, dev, getExtendedRequestConfig,
} from './api';
import CallToAction from './CallToAction';
import LoadingSVG from './Loading';
import { requestedLanguage } from './i18n';
import UserSettingsContext from './UserSettingsContext';

function Transfer() {
  const history = useHistory();
  const { t } = useTranslation('');

  const { userSettings } = useContext(UserSettingsContext);
  const { user = {} } = userSettings;

  const [clickedTransfer, setClickedTransfer] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [omatietovarantoInServices, setOmatietovarantoInServices] = useState(false);
  const [omatietovarantoAuthorized, setOmatietovarantoAuthorized] = useState(false);

  useEffect(() => {
    if (user.services?.some((s) => s.name === 'omakanta')) {
      setOmatietovarantoInServices(true);
    }
  }, [user.services]);

  useEffect(() => {
    const observationUrl = `${API}/omakanta/Observation?_count=1`;
    fetch(observationUrl, defaultRequestConfig)
      .then(checkForError)
      .then(() => {
        setOmatietovarantoAuthorized(true);
      })
      .catch(() => {
        setOmatietovarantoAuthorized(false);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  function clickTransfer(e) {
    e.preventDefault();
    setLoading(true);
    setClickedTransfer(true);
    if (dev) {
      history.push('/ready');
      return;
    }
    fetch(`${API}/user`, getExtendedRequestConfig({
      params: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { dataTransferPreferred: true },
      },
    }))
      .then(checkForError)
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(true);
      })
      .then(() => fetch(`${API}/logout`, defaultRequestConfig))
      .then(checkForError)
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(true);
      })
      .finally(() => {
        history.push('/ready');
      });
  }

  const TransferData = () => (
    <>
      <p><strong>

      </strong></p>
      <CallToAction action="/ready" onClick={clickTransfer}>Kopioi!</CallToAction>
      <p>
        {t('if-you-want-the-information-already-stored-in-omatietovaranto')} </p>
      <p>
        {t('we-try-to-copy-the-last-data-as-close-as-possible')} </p>
    </>
  );

  const RefreshOmakanta = () => (
    <>
      <p>
        {t('your-user-accounts-connection-to-omatietovaranto-does-not-seem-to-be-active')} </p>
      <a
        className="button primary btn"
        disabled={loading || clickedTransfer}
        href={
          `${
            API
          }/authenticate/omakanta?${
            requestedLanguage ? `l=${requestedLanguage}&` : ''
          }redirect=${
            encodeURIComponent(`${
              document.location.href
            }${
              requestedLanguage ? `?l=${requestedLanguage}` : ''
            }`)
          }`
        }
        onClick={dev && ((e) => {
          e.preventDefault();
          setOmatietovarantoInServices(true);
          setOmatietovarantoAuthorized(true);
        })}
      >
        {t('connect!')}
      </a>
    </>
  );

  const Error = () => (
    <strong>
      {t('something-went-wrong-please-try-again-later-or-contact-us')}
      : <a href="mailto:info@sensotrend.com">info@sensotrend.com</a>
    </strong>
  );

  return (
    <section id="transfer">
      <h1>{t('data-copying')}</h1>
      {(loading || clickedTransfer || !user) && <LoadingSVG />}
      {
        !loading && (!omatietovarantoInServices || !omatietovarantoAuthorized)
        && <RefreshOmakanta />
      }
      {!loading && omatietovarantoInServices && omatietovarantoAuthorized && <TransferData />}
      {error && <Error />}
    </section>
  );
}

export default Transfer;
