import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function GoodBye1() {
  const { t } = useTranslation('');
  return (
    <Fragment>
      <main id='privacy' className="textPage">
        <h1>
          {t('Data transfer between Sensotrend services and Omatietovaranto will end on 30.11.2023')}
        </h1>
        <small>{t('We will update this page as the case progresses.')}</small>
        <hr/>
        <p>
          <small>
            <strong>{t('Updated on International Day of Failure 13.10.2023')}</strong>
          </small>
        </p>
        <p>
          {t('The transfer of data between Sensotrend services and Omatietovaranto will end on 30.11.2023.')}
        </p>
        <p>
        {t('Omatietovaranto was to become')} <a
          href="https://www.linkedin.com/posts/mikaelrinnetmaki_sote-digitalisaatio-kanta-activity-7114349863919628288-iOUT">
          {t('LinkedIn article')}</a>.
        </p>
        <p>
        {t('Decided to disconnect our services')}
        </p>
        <h2 id="what-changes">{t('What changes?')}</h2>
        <p>
          {t('We will continue to handle')}
        </p>
        <p>
          {t('However, for the data uploaded')}
        </p>
        <h2 id="required-actions">{t('What do you have to do?')}</h2>
        <p>{t('Right now you dont have to do anything.')}</p>
        <p>
          {t('Due to the change')}
        </p>
        <p>
          {t('We also aim to make it easy')}
        </p>
        <p>
          {t('Our goal ist to have')} </p>
        <p>
          {t('We welcome any thoughts and concerns you may have about this change. Please contact us at')} <a
          href="mailto:info@sensotrend.com">info@sensotrend.com</a>.
        </p>
        <p className="signature">
          {t('Best regards')}<br/>
          <i>- {t('The Sensotrend team')}</i>
        </p>
        <p>{t('read-also')} <Link to="/goodbye-2">{t('newer-post-on-the-topic')}</Link>!</p>
        <p><br /></p>
        <h3>{t('For more information:')}</h3>
        <ul>
        <li>
            {t('A draft of the new privacy policy:')} <a
            href="https://dev.sensotrend.fi/dashboard/privacy">https://dev.sensotrend.fi/dashboard/privacy</a>
          </li>
          <li>
            {t('Draft new conditions of use:')} <a
            href="https://dev.sensotrend.fi/dashboard/eula">https://dev.sensotrend.fi/dashboard/eula</a>
          </li>
          <li>
            {t('More information on the Nightscout.fi service:')}  <a
            href="https://nightscout.fi/shutdown">https://nightscout.fi/shutdown</a>
          </li>
        </ul>
      </main>
    </Fragment>
  );
}

export default GoodBye1;
