import 'whatwg-fetch';

import { isLocalhost } from './serviceWorker';

const { host, port } = document.location;

// Check whether we're just running in dev mode.
// In that case, don't try to redirect...
export const dev = (document.location.host.indexOf(':3000') > 0)
  || document.location.hostname.indexOf('poolpraxdev') >= 0;
if (dev) {
  // eslint-disable-next-line no-console
  console.log('Running in dev mode, with external links disabled.');
}

export const API = isLocalhost
  ? `https://${port ? host.replace(`:${port}`, '') : host}:8443/api`
  : '/api';

export const defaultRequestConfig = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: isLocalhost ? 'include' : 'same-origin',
  mode: isLocalhost ? 'cors' : 'same-origin',
  cache: 'default',
};

export const postRequestConfig = {
  ...defaultRequestConfig,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  method: 'POST',
};

export const checkForError = (response) => {
  if (response.status < 200 || response.status >= 300) {
    const error = new Error(response.statusText || response.status);
    error.response = response;
    return Promise.reject(error);
  }

  if (response.status === 205) {
    return Promise.reject(response);
  }
  return response;
};

export const handleError = (response) => checkForError(response)
  .catch((error = {}) => {
    if (error.status === 205) {
      // TODO: cache something in localstorage?
      // console.log('Session information expired, reloading!');
      document.location.reload();
    }
  });

export const getJson = (response) => {
  if (!response.json) {
    const error = new TypeError('No JSON data.');
    error.response = response;
    return Promise.reject(error);
  }
  return response.json();
};

export const getExtendedRequestConfig = ({ params }) => {
  const config = { ...defaultRequestConfig };
  if (params.method) {
    config.method = params.method;
  }
  if (params.headers) {
    // Make a copy we can safely edit
    config.headers = { ...params.headers };
    Object.keys(params.headers).forEach((key) => {
      const value = params.headers[key];
      if (value === null) {
        delete config.headers[key];
      } else {
        config.headers[key] = value;
      }
    });
  }
  if (config.headers['Content-Type']
    && config.headers['Content-Type'].indexOf('application/json') >= 0
    && params.body) {
    config.body = JSON.stringify(params.body);
  } else {
    config.body = params.body;
  }
  return config;
};
