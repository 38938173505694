import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CallToAction from './CallToAction';
import './downloads.css';

const [Windows, Mac, Linux, unknown] = ['Windows', 'Mac', 'Linux', 'unknown'];

export const OS = (navigator.appVersion.indexOf('Win') >= 0 && Windows)
  || (navigator.appVersion.indexOf('Mac') >= 0 && Mac)
  || (navigator.appVersion.indexOf('Linux') >= 0 && Linux)
  || (navigator.appVersion.indexOf('X11') >= 0 && Linux)
  || unknown;

const others = [Windows, Mac, Linux].filter((os) => os !== OS);

function Uploader() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>Sensotrend Uploader</h1>
      </header>
      <main id="uploader">
        <p>{t('Sensotrend Uploader is available for multiple operating systems.')}</p>
        <p>{t("Select the correct installation file based on your computer's operating system from below.")}</p>
        {OS !== unknown ? (
          <Fragment>
            <h3>{t('Our best bet for you')}:</h3>
            <CallToAction action={`/download/${OS}`}>{t('Download uploader', { OS })}</CallToAction>
            <h3>{t('Other operating systems')}:</h3>
          </Fragment>
        ) : undefined}
        {others.map((os) => (
          <div key={os}>
            <Link className="small button" to={`/download/${os}`}>
              {t('Download uploader', { OS: os })}
            </Link>
          </div>
        ))}
        <p className="skip">
          <small>
            <Link to="/upload">{t('Skip')}</Link>
          </small>
        </p>
      </main>
    </Fragment>
  );
}

export default Uploader;
