import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function GoodBye3() {
  const { t } = useTranslation('');
  return (
    <Fragment>
      <header>
        <h1>
          {t('Data transfer between Sensotrend services and Kanta PHR has ended on December 1, 2023')}
        </h1>
      </header>
      <main id="terms-update">
        <p>
          <small>
            <strong>{t('Updated December 21, 2023')}</strong>
          </small>
        </p>
        <h2>{t('Terms of Use have been updated')}</h2>
        <p>
          {t('The use of Kanta PHR (Omatietovaranto) as a data source for Sensotrend\'s services has ended on December 1, 2023. For this reason, the terms of use of the services are also updated.')}
        </p>
        <p>
          {t('the-only-essential-change-in-the-terms-of-use-is')}
        </p>
        <p>
          {t('This currently applies only to data saved to the service with the Sensotrend Uploader application and, if you indicated your preference in time, to data copied from the Kanta PHR to Sensotrend\'s service before December 1, 2023. Data downloaded from different cloud services (Dropbox, Google Fit, Oura, Nightscout, etc.) will continue to be downloaded as part of the service directly from those services and will not be stored in Sensotrend\'s service.')}
        </p>
        <p>
          {t('In the future when you\'re using the Sensotrend Uploader app, your data is stored in Sensotrend\'s service.')}
        </p>
        <p>
          {t('None of your data has been lost in the change. The data stored in Omatietovaranto remains in omatietovaranto, but Sensotrend\'s services no longer write new data there, nor do they read data from there.')}
        </p>
        <p>
        {t('You can also read')} <Link to="/goodbye-1">{t('the first')}</Link> {t('and')} <Link to="/goodbye-2">{t('the second')}</Link> {t('update on the subject')}.
        </p>
        <p>
          {t('We welcome any thoughts and concerns you may have about this change. Please contact us at')} <a
            href="mailto:info@sensotrend.com">info@sensotrend.com</a>.
        </p>
        <p className="signature">
          {t('Best regards')}<br />
          <i>- {t('The Sensotrend team')}</i>
        </p>
        <h3 id="more-info">{t('For more information:')}</h3>
        <ul>
          <li>
            {t('Background')}
            <a
              href="https://www.linkedin.com/posts/mikaelrinnetmaki_sote-digitalisaatio-kanta-activity-7114349863919628288-iOUT">
              {t('LinkedIn article')}</a>
          </li>
          <li>
            {t('new-privacy-statement')} <a
              href="https://sensotrend.fi/dashboard/privacy">https://sensotrend.fi/dashboard/privacy</a>
          </li>
          <li>
            {t('new-terms-of-use')} <a
              href="https://sensotrend.fi/dashboard/eula">https://sensotrend.fi/dashboard/eula</a>
          </li>
          <li>
            {t('More information on the Nightscout.fi service:')}  <a
              href="https://nightscout.fi/shutdown">https://nightscout.fi/shutdown</a>
          </li>
        </ul>
      </main>
    </Fragment>
  );
}

export default GoodBye3;
