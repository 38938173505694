import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

function Bye() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>{t('Bye bye!')}</h1>
      </header>
      <main>
        <section>
          <p>{t('Your account and your saved information has now been deleted from Sensotrend\'s services.')}</p>
        </section>
      </main>
    </Fragment>
  );
}

export default Bye;
