import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserSettingsContext from './UserSettingsContext';
import './form.css';

/* Tidepool device names:

0: "medtronic600"
    1: "caresensble"
    2: "caresens"
3: "abbottfreestylelibre"
    4: "bayercontournext"
    5: "accuchekusb"
    6: "dexcom"
    7: "relionpremier"
8: "medtronic"
9: "omnipod"
10: "tandem"
    11: "abbottfreestyleneo"
    12: "bayercontour"
13: "animas"
    14: "onetouchverio"
    15: "onetouchverioiq"
    16: "onetouchultramini"
    17: "onetouchultra2"
    18: "truemetrix"
    19: "glucocardexpression"
    20: "glucocardshine"
    21: "glucocardshinehid"

*/

const glucometers = [
  {
    id: 'a',
    name: 'accuchekAvivaConnect',
    title: 'Accu-Chek Aviva Connect (Roche)',
    uploaderDeviceName: 'accuchekusb',
  },
  {
    id: 'b',
    name: 'accuchekGuide',
    title: 'Accu-Chek Guide (Roche)',
    uploaderDeviceName: 'accuchekusb',
  },
  {
    id: 'c',
    name: 'accuchekGuideMe',
    title: 'Accu-Chek Guide Me (Roche)',
    uploaderDeviceName: 'accuchekusb',
  },
  {
    id: 'd',
    name: 'accuchekInstant',
    title: 'Accu-Chek Instant (Roche)',
  },
  {
    id: 'e',
    name: 'contour',
    title: 'Contour (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontour',
  },
  {
    id: 'f',
    name: 'contourLink',
    title: 'Contour Link (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontour',
  },
  {
    id: 'g',
    name: 'contourNext',
    title: 'Contour Next (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontour',
  },
  {
    id: 'h',
    name: 'contourNextEZ',
    title: 'Contour Next EZ (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontour',
  },
  {
    id: 'i',
    name: 'contourNextLink',
    title: 'Contour Next Link (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontournext',
  },
  {
    id: 'j',
    name: 'contourNextLink24',
    title: 'Contour Next Link 2.4 (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontournext',
  },
  {
    id: 'k',
    name: 'contourNextOne',
    title: 'Contour Next One (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontournext',
  },
  {
    id: 'l',
    name: 'contourNextUsb',
    title: 'Contour Next USB (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontournext',
  },
  {
    id: 'm',
    name: 'contourPlus',
    title: 'Contour Plus (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontour',
  },
  {
    id: 'n',
    name: 'contourUsb',
    title: 'Contour USB (Ascensia / Bayer)',
    uploaderDeviceName: 'bayercontournext',
  },
  {
    id: 'o',
    name: 'caresensDualBle',
    title: 'CareSens Dual (iSens)',
    uploaderDeviceName: 'caresens, caresensble',
  },
  {
    id: 'p',
    name: 'caresensNPop',
    title: 'CareSens N POP (iSens)',
    uploaderDeviceName: 'caresens',
  },
  {
    id: 'q',
    name: 'caresensNPremierBle',
    title: 'CareSens N Premier BLE (iSens)',
    uploaderDeviceName: 'caresens, caresensble',
  },
  {
    id: 'r',
    name: 'freestyleFreedomLite',
    title: 'Freestyle Freedom Lite (Abbott)',
    uploaderDeviceName: 'abbottfreestyleneo',
  },
  {
    id: 's',
    name: 'freestyleLite',
    title: 'Freestyle Lite (Abbott)',
    uploaderDeviceName: 'abbottfreestyleneo',
  },
  {
    id: 't',
    name: 'freestyleOptimumNeo',
    title: 'Freestyle Optimum Neo (Abbott)',
    uploaderDeviceName: 'abbottfreestyleneo',
  },
  {
    id: 'u',
    name: 'freestylePrecisionNeo',
    title: 'Freestyle Precision Neo (Abbott)',
    uploaderDeviceName: 'abbottfreestyleneo',
  },
  {
    id: 'v',
    name: 'GlucocardExpression',
    title: 'Glucocard Expression (Arkray)',
    uploaderDeviceName: 'glucocardexpression',
  },
  {
    id: 'w',
    name: 'GlucocardShine',
    title: 'Glucocard Shine (Arkray)',
    uploaderDeviceName: 'glucocardshine',
  },
  {
    id: 'x',
    name: 'GlucocardShineConnex',
    title: 'Glucocard Shine Connex (Arkray)',
    uploaderDeviceName: 'glucocardshinehid',
  },
  {
    id: 'y',
    name: 'GlucocardShineExpress',
    title: 'Glucocard Shine Express (Arkray)',
    uploaderDeviceName: 'glucocardshinehid',
  },
  {
    id: 'z',
    name: 'GlucocardShineXL',
    title: 'Glucocard Shine XL (Arkray)',
    uploaderDeviceName: 'glucocardshine',
  },
  {
    id: 'A',
    name: 'OnetouchUltra2',
    title: 'OneTouch Ultra 2 (Lifescan)',
    uploaderDeviceName: 'onetouchultra2',
  },
  {
    id: 'B',
    name: 'OnetouchUltraMini',
    title: 'OneTouch UltraMini (Lifescan)',
    uploaderDeviceName: 'onetouchultramini',
  },
  {
    id: 'C',
    name: 'OnetouchVerio',
    title: 'OneTouch Verio (Lifescan)',
    uploaderDeviceName: 'onetouchverio',
  },
  {
    id: 'D',
    name: 'OnetouchVerioFlex',
    title: 'OneTouch Verio Flex (Lifescan)',
    uploaderDeviceName: 'onetouchverio',
  },
  {
    id: 'E',
    name: 'OnetouchVerioIq',
    title: 'OneTouch Verio IQ (Lifescan)',
    uploaderDeviceName: 'onetouchverioiq',
  },
  {
    id: 'F',
    name: 'OnetouchVerioReflect',
    title: 'OneTouch Verio Reflect (Lifescan)',
    uploaderDeviceName: 'onetouchverio',
  },
  {
    id: 'G',
    name: 'precisionXtra',
    title: 'Precision Xtra (Abbott)',
    uploaderDeviceName: 'abbottfreestyleneo',
  },
  {
    id: 'H',
    name: 'relionPremierBlu',
    title: 'ReliOn Premier Blu (ReliOn / Walmart)',
    uploaderDeviceName: 'relionpremier',
  },
  {
    id: 'I',
    name: 'relionPremierClassic',
    title: 'ReliOn Premier Classic (ReliOn / Walmart)',
    uploaderDeviceName: 'relionpremier',
  },
  {
    id: 'J',
    name: 'relionPremierVoice',
    title: 'ReliOn Premier Voice (ReliOn / Walmart)',
    uploaderDeviceName: 'relionpremier',
  },

  {
    id: 'K',
    name: 'trueMetrics',
    title: 'True Metrics (Trividia Health)',
    uploaderDeviceName: 'truemetrix',
  },
  {
    id: 'L',
    name: 'trueMetricsAir',
    title: 'True Metrics Air (Trividia Health)',
    uploaderDeviceName: 'truemetrix',
  },
  {
    id: 'M',
    name: 'trueMetricsGo',
    title: 'True Metrics Go (Trividia Health)',
    uploaderDeviceName: 'truemetrix',
  },
];

const cgms = [
  {
    id: 1,
    name: 'dexcomG4',
    title: 'Dexcom G4',
    uploaderDeviceName: 'dexcom',
  },
  {
    id: 2,
    name: 'dexcomG5',
    title: 'Dexcom G5',
    uploaderDeviceName: 'dexcom',
  },
  {
    id: 3,
    name: 'dexcomG6',
    title: 'Dexcom G6',
    uploaderDeviceName: 'dexcom',
  },
  {
    id: 4,
    name: 'eversense',
    title: 'Eversense (Senseonics)',
  },
  {
    id: 5,
    name: 'freestyleLibre',
    title: 'Freestyle Libre (Abbott)',
    uploaderDeviceName: 'abbottfreestylelibre',
  },
  {
    id: 6,
    name: 'freestyleLibrePro',
    title: 'Freestyle Libre Pro (Abbott)',
    uploaderDeviceName: 'abbottfreestylelibre',
  },
  {
    id: 7,
    name: 'guardianConnect',
    title: 'Guardian Connect (Medtronic)',
  },
];

const pumps = [
  {
    id: 'A',
    name: 'accuchekCombo',
    title: 'Accu-Chek Spirit Combo (Roche)',
  },
  {
    id: 'B',
    name: 'accuchekInsight',
    title: 'Accu-Chek Insight (Roche)',
  },
  {
    id: 'C',
    name: 'accuchekSolo',
    title: 'Accu-Chek Solo (Roche)',
  },
  {
    id: 'D',
    name: 'animasPing',
    title: 'Animas Ping',
    uploaderDeviceName: 'animas',
  },
  {
    id: 'E',
    name: 'animasVibe',
    title: 'Animas Vibe',
    uploaderDeviceName: 'animas',
  },
  {
    id: 'F',
    name: 'minimed523',
    title: 'Minimed 523 (Medtronic)',
    uploaderDeviceName: 'medtronic',
  },
  {
    id: 'G',
    name: 'minimed530G',
    title: 'Minimed 530G (Medtronic)',
    uploaderDeviceName: 'medtronic',
  },
  {
    id: 'H',
    name: 'minimed554',
    title: 'Minimed 554 (Medtronic)',
    uploaderDeviceName: 'medtronic',
  },
  {
    id: 'I',
    name: 'minimed640G',
    title: 'Minimed 640G (Medtronic)',
    uploaderDeviceName: 'medtronic600',
  },
  {
    id: 'J',
    name: '670g',
    title: 'Minimed 670G (Medtronic)',
    uploaderDeviceName: 'medtronic600',
  },
  {
    id: 'K',
    name: 'minimed723',
    title: 'Minimed 723 (Medtronic)',
    uploaderDeviceName: 'medtronic',
  },
  {
    id: 'L',
    name: 'minimed754',
    title: 'Minimed 754 (Medtronic)',
    uploaderDeviceName: 'medtronic',
  },
  {
    id: 'M',
    name: 'minimed780G',
    title: 'Minimed 780G (Medtronic)',
  },
  {
    id: 'N',
    name: 'omnipod',
    title: 'Omnipod (Insulet)',
    uploaderDeviceName: 'omnipod',
  },
  {
    id: 'O',
    name: 'omnipodDash',
    title: 'Omnipod DASH (Insulet)',
    uploaderDeviceName: 'omnipod',
  },
  {
    id: 'P',
    name: 'tFlex',
    title: 't:flex (Tandem)',
    uploaderDeviceName: 'tandem',
  },
  {
    id: 'Q',
    name: 'tSlim',
    title: 't:slim (Tandem)',
    uploaderDeviceName: 'tandem',
  },
  {
    id: 'R',
    name: 'tSlimG4',
    title: 't:slim G4 (Tandem)',
    uploaderDeviceName: 'tandem',
  },
  {
    id: 'S',
    name: 'tSlimX2',
    title: 't:slim X2 (Tandem)',
    uploaderDeviceName: 'tandem',
  },
];

const services = [
  {
    id: '1',
    name: 'carelink',
    title: 'CareLink (Medtronic)',
  },
  {
    id: '2',
    name: 'clarity',
    title: 'Clarity (Dexcom)',
  },
  {
    id: '3',
    name: 'libreView',
    title: 'LibreView (Abbott)',
  },
  {
    id: '4',
    name: 'nightscout',
    title: 'Nightscout',
  },
  {
    id: '5',
    name: 'smartLog',
    title: 'SmartLog (iSens)',
  },
  {
    id: '6',
    name: 'tidepool',
    title: 'Tidepool',
  },
];

export const categories = [
  {
    name: 'glucometers',
    title: 'Blood glucose meters',
    devices: glucometers,
  },
  {
    name: 'cgms',
    title: 'Continuous glucose monitors',
    devices: cgms,
  },
  {
    name: 'pumps',
    title: 'Insulin pumps',
    devices: pumps,
  },
  {
    name: 'services',
    title: 'Cloud services',
    devices: services,
  },
];

export const devices = categories.reduce((o, c) => ({
  ...o,
  [c.name]: c.devices.reduce((o2, d) => ({ ...o2, [d.id]: d }), {}),
}), {});

export function populateDevices(devicesString = '') {
  const categorized = (devicesString.split(',') || []).map((s) => s.split(''));
  return categories.reduce((o, category, i) => ({
    ...o,
    [category.name]: (categorized[i] || []).map((d) => devices[category.name][d]),
  }), {});
}

export const noDevices = Object.freeze({
  glucometers: Object.freeze([]),
  cgms: Object.freeze([]),
  pumps: Object.freeze([]),
  services: Object.freeze([]),
});

export function buildDeviceString(devs = noDevices) {
  return Object.values(devs).map((arr) => arr.map((d) => d.id).join('')).join(',');
}

function Devices() {
  const { t } = useTranslation('translations');

  const { userSettings, setUserSettings } = useContext(UserSettingsContext);

  const { preselectedDevices = '', selectedDevices = noDevices } = userSettings;

  const selectedDeviceIds = buildDeviceString(selectedDevices);

  const [selectedState, setSelectedState] = useState(selectedDeviceIds || preselectedDevices);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!selectedState && preselectedDevices) {
      // Initial info from the context
      setSelectedState(preselectedDevices);
    }
  }, [preselectedDevices, selectedState, setSelectedState]);

  const [processing, setProcessing] = useState(false);

  const history = useHistory();

  function processForm(event) {
    event.preventDefault();
    if (processing) {
      return;
    }
    setProcessing(true);
    const stateDevices = populateDevices(selectedState);
    setUserSettings({
      ...userSettings,
      selectedDevices: stateDevices === noDevices ? devices : stateDevices,
    });
    setProcessing(false);
    history.push(stateDevices ? `/uploaders?d=${selectedState}` : '/coming');
  }

  // console.log('Selected state', { selectedState, selectedDevices });

  return (
    <Fragment>
      <header>
        <h1>{t('Transfer your data to Kanta PHR')}</h1>
      </header>
      <main>
        <p>{t('Data transfer options to the PHR depend on which devices you are using.')}</p>
        <h2>{t('Select your devices here.')}</h2>
        <div className="input">
          <input
            id="search"
            className={search ? 'has-content' : ''}
            type="search"
            value={search}
            autoFocus
            onChange={(event) => setSearch(event.target.value.toLowerCase())}
          />
          <label htmlFor="search">{t('Search')}</label>
        </div>
        <form onSubmit={processForm}>
          {categories.map((category, i) => (
            <fieldset key={category.name}>
              <legend>{t(category.title)}</legend>
              {category.devices.map((entry) => (!search
                || entry.title.toLowerCase().indexOf(search) >= 0
                || entry.name.toLowerCase().indexOf(search) >= 0 ? (
                  <div key={entry.id}>
                    <input
                      id={entry.name}
                      type="checkbox"
                      name={entry.name}
                      checked={selectedState.split(',')[i].indexOf(entry.id) >= 0}
                      onChange={(event) => {
                        const categorized = selectedState.split(',');
                        if (event.target.checked) {
                          categorized[i] += entry.id;
                        } else {
                          categorized[i] = categorized[i].replace(entry.id, '');
                        }
                        setSelectedState(categorized.join(','));
                      }}
                    />
                    <label htmlFor={entry.name}>{entry.title}</label>
                  </div>
                ) : undefined))}
            </fieldset>
          ))}
          <input type="submit" value="OK" />
        </form>
      </main>
    </Fragment>
  );
}

export default Devices;
