import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from './i18n.js';
import CallToAction from './CallToAction';
import InstructionBlock from './InstructionBlock';
import emojiSmilingFace from './images/emojiSmilingFaceWithSmilingEyes.png';
import emojiGrinningFace from './images/emojiGrinningFace.png';
import emojiThinkingFace from './images/emojiThinkingFace.png';
import { wellcomeImage } from './imageLanguageHandler/wellcomeImage.js';
import { setPwdImage } from './imageLanguageHandler/setPwdImage.js';
import { signupImage } from './imageLanguageHandler/signupImage.js';
import { loginImage } from './imageLanguageHandler/loginImage.js';
import { goToKantaImage } from './imageLanguageHandler/goToKantaImage.js';
import { loginComplete } from './imageLanguageHandler/loginComplete.js';
import { accountCreationImage } from './imageLanguageHandler/accountCreationImage.js';
import { checkEmailImage } from './imageLanguageHandler/checkEmailImage.js';
import { kantaIdentificationImage } from './imageLanguageHandler/kantaIdentificationImage.js';
import { kantaApprovalImage } from './imageLanguageHandler/kantaApprovalImage.js';
import pdf from './insructions/Sensotrend_kayttoon-otto.pdf';
import './instructions.css';

export function Header() {
  const { t } = useTranslation('translations');

  return (
    <header>
      <h1>{t('Deployment instructions')}</h1>
      <h3>{t('Connecting the service to PHR')}</h3>
    </header>
  );
}

export function Intro({ isPrinting }) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <p>
        {t(
          "With the help of Sensotrend's service package, your information is stored in PHR and visualized in a way that supports your self-care.",
        )}{' '}
        {t('A pdf version of the instructions can be found')}{' '}
        <a
          href={
            isPrinting || navigator.userAgent === 'ReactSnap'
              ? `https://www.sensotrend.fi/connect${pdf}`
              : pdf
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('here')}
        </a>
        .
      </p>
      <p className="smiley-helper">
        <span className="smiley-helper-face">
          <img src={emojiSmilingFace} alt="" />
        </span>
        {t(
          'These instructions will help you set up a service package that you only need to do once.',
        )}
      </p>
    </section>
  );
}

export function ConnectOmatietovaranto(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
        {...props}
        instructionText={
          <Fragment>
            <p>
              <h4>{t('Start by connecting the Sensotrend service to PHR.')}</h4>
            </p>
            {t('Go to')}{' '}
            <a href={t('Link-ConnectWelcomePage')} rel="noopener noreferrer" target="_blank">
              {t('Link-ConnectWelcomePage')}
            </a>{' '}
            {t(
              '(1). Start deploying the service with the “Let’s continue!” button (2), after which the login page of our service will open for you. At this point, you can log in with your already existing Sensotrend usernames with the “Log in!” button (3) or register as a new user with the “Register!” button (4).',
            )}
          </Fragment>
        }
        questionsAndAnswers={{
          [t('The link does not open.')]: (
            <span className="answers">
              <img src={emojiSmilingFace} alt="" />{' '}
              {t(
                'Try copying or typing the address into the address field of your Internet browser.',
              )}
            </span>
          ),
          [t(
            'I have a paper copy of the instructions and I don’t know how to get to the address.',
          )]: (
            <span className="answers">
              <img src={emojiSmilingFace} alt="" />{' '}
              {t(
                'This is an internet address. Turn on your computer, open an Internet browser, and type the address in the address field of the browser.',
              )}
            </span>
          ),
          [t(
            'I get an error 404 even though my internet connection is otherwise working normally.',
          )]: (
            // How to handle email sending to the correct address ("mailto:support@sensotrend.com"
            // vs. "mailto:tuki@sensotrend.com")?
            <span className="answers">
              <img src={emojiThinkingFace} alt="" />{' '}
              {t(
                'Strange, this should not happen. I wonder if our service is having some problems at this moment. Would you be kind and contact our product support by email',
              )}{' '}
              <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a>
              {t(', so we try to figure out the problem.')}
            </span>
          ),
        }}
        restContent={
          <Fragment>
            <figure>
              <img
                src={wellcomeImage[i18n.language]?.() || wellcomeImage.en()}
                alt={t('Welcome page')}
              />
            </figure>
            <figure>
              <img
                src={signupImage[i18n.language]?.() || signupImage.en()}
                style={{ maxWidth: '50%' }}
                alt={t('Logging in to Sensotrend Connect')}
              />
            </figure>
          </Fragment>
        }
      />
    </section>
  );
}

export function Signin(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
        {...props}
        instructionText={
          <Fragment>
            <h4>{t('If you selected “Log in!” in step 1')}</h4>
            {t(
              ', you will be redirected to the login page. You can log in with your Sensotrend account by entering your email address and password (1) and then pressing the “OK” button (2).',
            )}
          </Fragment>
        }
        questionsAndAnswers={{
          [t('I have a Sensotrend account, but I have forgotten my password.')]: (
            <Fragment>
              <span className="answers">
                <img src={emojiSmilingFace} alt="" />{' '}
                {t('No worries. There is a link (1) on the login page to set a new password.')}
              </span>
              <figure>
                <img
                  src={setPwdImage[i18n.language]?.() || setPwdImage.en()}
                  alt={t('Logging in to Sensotrend Connect')}
                />
              </figure>
            </Fragment>
          ),
        }}
        restContent={
          <figure>
            <img
              src={loginImage[i18n.language]?.() || loginImage.en()}
              style={{ maxWidth: '100%' }}
              alt={t('Log in')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function Register(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
        {...props}
        instructionText={
          <Fragment>
            <h4>{t('If you selected “Register!” in step 1')}</h4>
            {t(
              ', you will be redirected to the registration page. Enter your name, e-mail address and choose a password for yourself (1). Create a new password for this username. Do not use a password that you use to log in to other services. Then press the “OK” button (2). You will receive a confirmation email in your email.',
            )}
          </Fragment>
        }
        questionsAndAnswers={{
          [t("I didn't receive an email where the confirmation code should be.")]: (
            <span className="answers">
              <img src={emojiSmilingFace} alt="" />{' '}
              {t(
                'Have you looked at spam messages? Unfortunately, sometimes the messages sent by our service are redirected there.',
              )}
              <br />
              <br />
              {t(
                'There may also be a slight delay in forwarding the emails. It\'s worth waiting for a moment if the message is not delivered right away.',
              )}
            </span>
          ),
        }}
        restContent={
          <Fragment>
            <figure>
              <img
                src={accountCreationImage[i18n.language]?.() || accountCreationImage.en()}
                style={{ maxWidth: '100%' }}
                alt={t('Sign up for Sensotrend Connect')}
              />
            </figure>
            {t(
              'The service will ask you for the verification code you received in your email. You can confirm your login with the link in the email or alternatively enter the code in the “Verification code” field (1) and press the “OK” button (2).',
            )}
            <figure>
              <img
                src={checkEmailImage[i18n.language]?.() || checkEmailImage.en()}
                alt={t('Check your email')}
              />
            </figure>
            {t(
              'Once your username has been successfully registered, a successful registration message will be displayed. Now press “Log in!” button (1), then you can enter your email address and password (2) and press the “OK” button (3).',
            )}
            <figure>
              <img
                src={loginComplete[i18n.language]?.() || loginComplete.en()}
                style={{ maxWidth: '100%' }}
                alt={t('Everything is ready')}
              />
            </figure>
          </Fragment>
        }
      />
    </section>
  );
}

export function SigninOmatietovaranto(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
        {...props}
        instructionText={
          <Fragment>
            <h4>
              {t(
                'Next, you will be directed to My Kanta identification so that you can connect the service to Kanta PHR.',
              )}
            </h4>{' '}
            {t('Now press “Navigate to My Kanta!” button (1).')}
          </Fragment>
        }
        questionsAndAnswers={{
          [t(
            'Why do I have to log in to Kanta PHR and Sensotrend services separately? Why is one login not enough?',
          )]: (
            <span className="answers">
              <img src={emojiSmilingFace} alt="" />{' '}
              {t(
                'Kanta PHR is a service belonging to the Kanta services and maintained by Kela. Therefore, a separate login to Kanta PHR is required. However, authentication only needs to be done when implementing Sensotrend services. When using our services, you no longer have to worry about logging in to Kanta PHR.',
              )}
            </span>
          ),
        }}
        restContent={
          <Fragment>
            <figure>
              <img
                src={goToKantaImage[i18n.language]?.() || goToKantaImage.en()}
                alt={t('Navigate to My Kanta')}
              />
            </figure>
            {t(
              'Select the authentication method, perform the authentication and finally press the “Continue to service” button (1) in the authentication window.',
            )}
            <figure>
              <img
                src={kantaIdentificationImage[i18n.language]?.() || kantaIdentificationImage.en()}
                style={{ maxWidth: '100%' }}
                alt={t('Kanta identification')}
              />
            </figure>
          </Fragment>
        }
      />
    </section>
  );
}

export function ApprovalOmatietovaranto(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
        {...props}
        instructionText={
          <Fragment>
            <p>
              <h4>{t('If this is the first application you have deployed in Kanta PHR,')}</h4>{' '}
              {t(
                "the service asks you to accept the terms of use of Kanta PHR. Accept the terms of use, after which the service will automatically proceed to accept the access rights to Sensotrend's services.",
              )}
            </p>
            {t(
              'Kanta PHR will now ask you to accept the licenses for Sensotrend Connect. Press the “Authorize” button (1).',
            )}
          </Fragment>
        }
        questionsAndAnswers={{
          [t(
            'Where will my data end up if I give Sensotrend Connect permission to process my data?',
          )]: (
            <span className="answers">
              <img src={emojiSmilingFace} alt="" />{' '}
              {t(
                'Our service will not pass on your information without your permission. We also do not store your own information or use it for profiling.',
              )}
              <br />
              <br />
              {t(
                'The Sensotrend Connect service stores your data in Kanta PHR, from which other services can use it. However, even this will only happen with your permission. Each service separately requests permission to process your data.',
              )}
            </span>
          ),
        }}
        restContent={
          <figure>
            <img
              src={kantaApprovalImage[i18n.language]?.() || kantaApprovalImage.en()}
              style={{ maxWidth: '100%' }}
              alt={t('Access rights for Sensotrend Connect')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function DoneSmiley() {
  const { t } = useTranslation('translations');

  return (
    <section>
      <p className="smiley-helper">
        <span className="smiley-helper-face">
          <img src={emojiGrinningFace} alt="" />
        </span>
        {t('The first phase is now complete.')}
      </p>
      <p>{t('Next, you need apps for data transfer and viewing.')}</p>
    </section>
  );
}

export function Content(props) {
  return (
    <Fragment>
      <Intro {...props} />
      <ConnectOmatietovaranto {...props} />
      <Signin {...props} />
      <Register {...props} />
      <SigninOmatietovaranto {...props} />
      <ApprovalOmatietovaranto {...props} />
      <DoneSmiley />
    </Fragment>
  );
}

function Instructions() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <Header />
      <main id="instructions" style={{ '--startCounterPrevValue': 0 }}>
        <Content />
        <CallToAction action="/instructions/sensotrend-uploader" style={{ float: 'right' }}>
          {t('Next step')} &rarr;
        </CallToAction>
      </main>
    </Fragment>
  );
}

export default Instructions;
