import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from './i18n.js';
import CallToAction from './CallToAction';
import InstructionBlock from './InstructionBlock';
import emojiSmilingFace from './images/emojiSmilingFaceWithSmilingEyes.png';
import emojiHuggingFace from './images/emojiHuggingFace.png';
import { viewDataImage } from './imageLanguageHandler/viewData.js';
import { dashboardAGPImage } from './imageLanguageHandler/dashboardAGP.js';
import { dashboardSharingLinkImage } from './imageLanguageHandler/dashboardSharingLink.js';
import { dashboardSharingImage } from './imageLanguageHandler/dashboardSharing.js';
import './instructions.css';

export function Header() {
  const { t } = useTranslation('translations');

  return (
    <header>
      <h1>{t('Deployment instructions')}</h1>
      <h3>{t('Viewing the data')}</h3>
    </header>
  );
}

export function Continue(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('Enable Sensotrend Dashboard to view your data.')}</h4>
            <p>{t('To access the application, press “View data!” button (1).')}</p>
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Do I have other ways to see the data I uploaded to the PHR with the Sensotrend Uploader?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('The PHR you are using is likely to have an interface for viewing data.')}
              </span>,
          }
        }
        restContent = {
          <Fragment>
            <figure>
              <img
                src={viewDataImage[i18n.language]?.() || viewDataImage.en()}
                alt={t('View your data')}
              />
            </figure>
            {t('You should now have a view like this.')}
            <figure>
              <img
                src={dashboardAGPImage[i18n.language]?.() || dashboardAGPImage.en()}
                style={{ maxWidth: '100%' }}
                alt={t('AGP report')}
              />
            </figure>
          </Fragment>
        }
      />
    </section>
  );
}

export function DashboardSharing(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('At this point, it’s a good idea to go and give the practitioner who treat you permission to view your data.')}</h4> {t('To do this, your healthcare provider has sent you a request to view your information. You can see the requests by clicking on your name in the top right corner of the view (1) and selecting “Sharing” (2).')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('To whom will my data end up if I give the health care unit permission to view it?')]:
            <span className="answers">
              <img src={emojiSmilingFace} alt="" /> {t('Only your care team will be able to view your information using the patient information system at their disposal. The same applies to this in practice as to your other information that the professional treating you will have to deal with when treating you.')}
            </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={dashboardSharingLinkImage[i18n.language]?.() || dashboardSharingLinkImage.en()}
              style={{ maxWidth: '100%' }}
              alt={t('Data sharing')}
            />
          </figure>
        }
      />
    </section>
  );
}

export function DashboardSharingAllow(props) {
  const { t } = useTranslation('translations');

  return (
    <section>
      <InstructionBlock
       {...props}
        instructionText = {
          <Fragment>
            <h4>{t('When you click on the “Allow” button (1) in the data sharing request sent by the healthcare unit that cares for you,')}</h4> {t('that healthcare unit will be given permission to use your data as part of the treatment provided to you.')}
          </Fragment>
        }
        questionsAndAnswers = {
          {
            [t('Do I have the option to revoke my permit?')]:
              <span className="answers">
              <img src={emojiSmilingFace} alt="" /> {t('Yes you do. You will be able to manage the permissions you grant through this interface. You can revoke the permit and reissue it whenever you want. Your healthcare professional will not be able to see information through our service if you revoke your permission. Please note, however, that information transmitted through our services will not be available when a professional is treating you if the authorization is not valid.')}
              </span>,
            [t('Where will my data be transferred if I give permission to use it?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('With the permission, your data will not be transferred anywhere. When you give permission, that healthcare unit has the right to view your information as part of the treatment you are receiving.')}
              </span>,
            [t('Do I have the option to delete data that has been used in my treatment?')]:
              <span className="answers">
                <img src={emojiSmilingFace} alt="" /> {t('You have the rights to your data under the European Data Protection Regulation (GDPR). If you wish to exercise these rights with respect to your information stored in connection with the treatment you have received, you may contact the healthcare department where your information was processed.')}
              </span>,
          }
        }
        restContent = {
          <figure>
            <img
              src={dashboardSharingImage[i18n.language]?.() || dashboardSharingImage.en()}
              style={{ maxWidth: '100%' }}
              alt={t('Data sharing')}
            />
          </figure>
        }
      />
  </section>
  );
}

export function SmileyDone() {
  const { t } = useTranslation('translations');

  return (
    <section>
      <p class="smiley-helper">
        <span class="smiley-helper-face"><img src={emojiHuggingFace} alt="" /></span>
        {t('We are ready!')} <br/><br/>{t('The set of services has now been successfully deployed!')}
      </p>
    </section>
  );
}

export function LinkToDashboardGuide({ isPrinting }) {
  const { t } = useTranslation('translations');

  return (
    <section>
        <b>{t('Instructions for viewing and utilizing the data stored in the PHR can be found from')}
          { !isPrinting ? <a href="https://www.sensotrend.fi/dashboard/helpIntro"> {t('here.')}</a>
            : <a href="https://www.sensotrend.fi/connect/instructions/dashboard" className="forceWrap"> https://www.sensotrend.fi/connect/instructions/dashboard</a>
          }
        </b>
        <p></p>
    </section>
  );
}

export function Content(props) {
  return (
    <Fragment>
      <Continue {...props} />
      <DashboardSharing {...props} />
      <DashboardSharingAllow {...props} />
      <SmileyDone />
      <LinkToDashboardGuide {...props} />
    </Fragment>
  );
}

function InstructionsDashboardIntro() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <Header />
      <main id="instructions" style={{ '--startCounterPrevValue': 17 }}>
        <Content />
        <CallToAction action="/instructions/sensotrend-uploader">&larr; {t('Previous step')}</CallToAction>
      </main>
    </Fragment>
  );
}

export default InstructionsDashboardIntro;
