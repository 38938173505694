import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const { hostname, search } = document.location;
const langParam = search.match(/[?&]l=([^&]+)/);
export const requestedLanguage = langParam && langParam[1];

// const defaultLocale = 'fi';    // For testing purposes only.
const defaultLocale = requestedLanguage || (hostname.indexOf('sensotrend.fi') >= 0 ? 'fi' : 'en');

const debug = process.env.NODE_ENV === 'development';

if (debug) {
  // eslint-disable-next-line no-console
  console.log('Default locale', defaultLocale, search, langParam, hostname);
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?n=${new Date().getTime()}`,
      },
      debug,
      lng: defaultLocale,
      supportedLngs: ['en', 'de', 'es', 'fi', 'fr', 'it', 'no', 'sv'],
      fallbackLng: defaultLocale,
      // have a common namespace used around the full app
      ns: ['translations'],
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      react: {
        useSuspense: true,
      },
    },
    (err, t) => {
      if (err) {
        if (debug) {
          // eslint-disable-next-line no-console
          console.error('Error loading localizations', err);
        }
      }
    },
  );

export default i18n;
