import React, { Fragment } from 'react';

import BuildTime from './BuildTime';
import manufacturerImage from './images/manufacturer.png';
import ceImage from './images/ce.svg';
import eInstruction from './images/e-instruction.png';
import udi from './images/udi.png';
import manufacturingDate from './images/manufacturingDate.png';
import './instructions.css';
import './index.css';

function InstructionsUploaderSE() {
  return (
    <Fragment>
      <header>
        <h1>Säkerhetsinformation - Sensotrend Uploader</h1>
      </header>
      <small>Uppdaterad 2022-02-24.</small>
      <main id="instructions">
        <section>
          <h2>Avsedda ändamål av applikationen</h2>
          <p>
            Sensotrend Uploader är en datorapplikation som stöder behandlingen av diabetes genom
            att överföra data som samlats in av blodsockermätare, glukossensorer och insulinpumpar
            och liknande enheter till molntjänster.
          </p>
          <p>
            Sensotrend Uploader är utformad för diabetiker som ansvarar för sin vård. Vi vill dock
            inte begränsa användningen av applikationen till endast denna grupp användare. När det
            gäller andra användargrupper anser vi att den som ansvarar för vården också är ansvarig
            för användningen av Sensotrend Uploader.
          </p>
        </section>
        <section>
          <h2>Begränsningar</h2>
          <p>
            Användningen av applikationen ersätter inte vården som tillhandahålls av en
            vårdpersonal utan kompletterar den. Om det finns betydande förändringar i din
            vårdbalans, kontakta din vårdgivare.
          </p>
          <p>
            Gör inte betydande förändringar i din behandling baserad endast på data överfört av
            Sensotrend Uploader. Innan du fattar ett behandlingsbeslut ska data överfört av
            Sensotrend Uploader jämföras med data från den ursprungliga datakällan för att
            säkerställa att hela dataöverföringskedjan har fungerat tillförlitligt.
          </p>
          <p>
            Om allvarliga incidenter inträffar under användningen av applikationen ska de
            rapporteras till vår kundsupport på <a
            href="mailto:support@sensotrend.com">support@sensotrend.com</a> och
            tillsynsmyndigheten.
          </p>
          <h3>Information relaterad till systemkrav</h3>
          <p>
            Sensotrend Uploader är utformat för att fungera med moderna Windows-, Mac- och
            Linux-datorer. Det är dock inte möjligt att säkerställa att applikationen fungerar med
            alla konfigurationer på marknaden. Om du märker någon felaktig, oväntad eller
            ofullständig aktivitet som kan bero på hårdvaran eller programvaran du använder,
            vänligen kontakta vår kundsupport på <a
            href="mailto:support@sensotrend.com">support@sensotrend.com</a> så att vi kan lösa och,
            om möjligt, rätta till problemet.
          </p>
          <p>
            Använd inte applikationen för att övervaka din hälso- och vårdbalans om den verkar
            fungera felaktigt.
          </p>
        </section>
        <aside id="label">
          <h2>Produktinformation</h2>
          <p>Sensotrend Uploader</p>
          <section id="ce">
            <img className="labeling" src={ceImage} alt="CE" />
            <p>
              Sensotrend Uploader är en medicinteknisk produkt av klass I. Se <a href={
                `${
                  navigator.userAgent === 'ReactSnap'
                    ? 'https://www.sensotrend.fi/connect'
                    : process.env.PUBLIC_URL
                }/DECO-T5-DC-MDD_EU_Declaration_of_Conformity_-_Sensotrend_Uploader_-_signed.pdf`
              }
              rel="noopener noreferrer" target="_blank">försäkran om överensstämmelse</a>.
            </p>
          </section>
          <section id="eInstruction">
          <img alt="Elektronisk instruktion" title="Elektronisk instruktion" src={eInstruction} />
            <img
              className="labeling"
              src={eInstruction}
              alt="Elektronisk instruktion"
            />
            {
            /* This clause is for EU regulation for electronic instructions (2021/2226), article 6,
            clause 1 and 3d.
            */
            }
            <p>
              Denna bruksanvisning tillhandahålls endast i elektronisk form. Du kan begära en
              pappersversion från <a
              href="mailto:support@sensotrend.com">support@sensotrend.com</a>. Pappersversionen
              kommer att levereras inom 7 kalenderdagar efter mottagandet av begäran.
            </p>
          </section>
          <section id="device">
            <img className="labeling" src={udi} alt="UDI" />
            <p>D-FIMF000000500SU000001QT</p>
          </section>
          <section id="date">
          <img alt="Tillverkningsdatum" title="Tillverkningsdatum" src={manufacturingDate} />
          {BuildTime}
          </section>
          <section id="manufacturer">
            <img className="labeling" src={manufacturerImage} alt="Tillverkare" />
            <address>
              Sensotrend Oy<br />
              Tampellan esplanadi 19 A 55<br />
              33180 Tammerfors<br />
              Finland<br />
              <br />
              <a href="mailto:support@sensotrend.com">support@sensotrend.com</a><br />
            </address>
          </section>
        </aside>
      </main>
    </Fragment >
  );
}

export default InstructionsUploaderSE;
