import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';

import {
  API, checkForError, defaultRequestConfig, dev, getJson,
} from './api';
import { requestedLanguage } from './i18n';
import AcceptToc from './AcceptToc';
import Bye from './Bye';
import ConnectKantaPHR from './ConnectKantaPHR';
import Connected from './Connected';
import Download from './Download';
import Devices from './Devices';
import Eula from './Eula';
import Footer from './Footer';
import GoodBye1 from './GoodBye1';
import GoodBye2 from './GoodBye2';
import GoodBye3 from './GoodBye3';
import Instructions from './Instructions';
import InstructionsConnectSE from './InstructionsConnectSE';
import InstructionsLanding from './InstructionsLanding';
import InstructionsDashboardIntro from './InstructionsDashboardIntro';
import InstructionsLinks from './InstructionsLinks';
import InstructionsPrint from './InstructionsPrint';
import InstructionsUploader from './InstructionsUploader';
import InstructionsUploaderAbbottLibre from './InstructionsUploaderAbbottLibre';
import InstructionsUploaderAbbottLite from './InstructionsUploaderAbbottLite';
import InstructionsUploaderAbbottNeo from './InstructionsUploaderAbbottNeo';
import InstructionsUploaderAbbottXtra from './InstructionsUploaderAbbottXtra';
import InstructionsUploaderAnimas from './InstructionsUploaderAnimas';
import InstructionsUploaderAscensiaContour from './InstructionsUploaderAscensiaContour';
import InstructionsUploaderAscensiaContourNextMeter from './InstructionsUploaderAscensiaContourNextMeter';
import InstructionsUploaderCaresens from './InstructionsUploaderCaresens';
import InstructionsUploaderCaresensBt from './InstructionsUploaderCaresensBt';
import InstructionsUploaderDexcom from './InstructionsUploaderDexcom';
import InstructionsUploaderInsuletOmnipod from './InstructionsUploaderInsuletOmnipod';
import InstructionsUploaderInsuletOmnipodDASH from './InstructionsUploaderInsuletOmnipodDASH';
import InstructionsUploaderMedtronic from './InstructionsUploaderMedtronic';
import InstructionsUploaderMedtronic6xG from './InstructionsUploaderMedtronic6xG';
import InstructionsUploaderOneTouchVerio from './InstructionsUploaderOneTouchVerio';
import InstructionsUploaderOneTouchVerioIQ from './InstructionsUploaderOneTouchVerioIQ';
import InstructionsUploaderOneTouchUltraMini from './InstructionsUploaderOneTouchUltraMini';
import InstructionsUploaderOneTouchUltra2 from './InstructionsUploaderOneTouchUltra2';
import InstructionsUploaderReliOn from './InstructionsUploaderReliOn';
import InstructionsUploaderRoche from './InstructionsUploaderRoche';
import InstructionsUploaderSE from './InstructionsUploaderSE';
import InstructionsUploaderTandem from './InstructionsUploaderTandem';
import InstructionsUploaderTrividia from './InstructionsUploaderTrividia';
import InstructionsUploaderTrividiaGo from './InstructionsUploaderTrividiaGo';
import InstructionsUploaders from './InstructionsUploaderDev';
import Privacy from './Privacy';
import Ready from './Ready';
import Registered from './Registered';
import ScrollToTop from './ScrollToTop';
import Transfer from './Transfer';
import Upload from './Upload';
import Uploader from './Uploader';
import Uploaders from './Uploaders';
import UserSettingsContext from './UserSettingsContext';
import View from './View';
import Welcome from './Welcome';

const base = process.env.PUBLIC_URL;
const supportsHistory = 'pushState' in window.history;
const noUser = {};

if (dev) {
  // eslint-disable-next-line no-console
  console.log('Requested language', requestedLanguage);
}

function RedirectExternal({ to }) {
  if (dev) {
    const redirectMatch = to.match(/(\?redirect=|&redirect=)([^&]+)/) || [];
    const redirect = redirectMatch.length ? decodeURIComponent(redirectMatch[2]) : '/connect';
    // eslint-disable-next-line no-console
    console.log(`In production would redirect to ${to}`);
    document.location = redirect;
  } else if (navigator.userAgent !== 'ReactSnap') {
    document.location.replace(to.pathname || to);
  }
  return null;
}

function Routes() {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [user, setUser] = useState(undefined);

  const search = document.location.search || '';

  const tokenMatch = search.match(/(\?t=|&t=)([^&]+)/) || [];
  const token = tokenMatch[2];

  const devicesMatch = search.match(/(\?d=|&d=)([^&]+)/) || [];
  const preselectedDevices = devicesMatch[2];

  useEffect(() => {
    if (preselectedDevices !== userSettings.preselectedDevices
      || token !== userSettings.token) {
      setUserSettings({
        ...userSettings,
        preselectedDevices,
        token,
      });
    }
  }, [preselectedDevices, setUserSettings, token, userSettings]);

  useEffect(() => {
    let active = true;
    if (navigator.userAgent === 'ReactSnap') {
      // No user data to snapshots!
      setUser(noUser);
    } else if (user === undefined) {
      fetch(`${API}/user`, defaultRequestConfig)
        .then(checkForError)
        .then(getJson)
        .then((u) => {
          setUser(u);
          setUserSettings({
            ...userSettings,
            user: u,
          });
        })
        .catch(() => {
        // no user
          if (active) {
            setUser(noUser);
          }
        });
    }
    return (() => {
      active = false;
    });
  }, [user, setUser, setUserSettings, userSettings]);

  function ProtectedRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => (((user !== noUser) || dev)
          ? (
             <Fragment>
               {children}
             </Fragment>
          ) : (
            <RedirectExternal
              to={`/signin/?redirect=${
                encodeURIComponent(document.location.href)
              }${
                userSettings.preselectedDevices
                  ? `?d=${userSettings.preselectedDevices}`
                  : ''
              }${
                userSettings.token
                  ? `${userSettings.preselectedDevices ? '&' : '?'}t=${userSettings.token}`
                  : ''
              }${
                requestedLanguage
                  ? `${(userSettings.preselectedDevices || userSettings.token) ? '&' : '?'}l=${requestedLanguage}`
                  : ''
              }`}
            />
          ))
        }
      />
    );
  }

  if (user === undefined) {
    // Still initializing
    return (
      <div>...</div>
    );
  }

  return (
    <Router basename={base} forceRefresh={!supportsHistory}>
      <article>
        <Switch>
          <Route path="/signin">
            <RedirectExternal
              to={`/signin?redirect=${
                encodeURIComponent(
                  `${
                    document.location.href.substring(0, document.location.href.lastIndexOf('/'))
                  }/registered${
                    document.location.search
                  }${
                    requestedLanguage
                      ? `${document.location.search.length ? '&' : '?'}l=${requestedLanguage}`
                      : ''
                  }`,
                )
              }${requestedLanguage ? `&l=${requestedLanguage}` : ''}`}
            />
          </Route>
          <Route path="/dashboard">
            <RedirectExternal to="/dashboard/agp" />
          </Route>
          <Route path="/sharing">
            <RedirectExternal to="/dashboard/sharing/" />
          </Route>
          <Route path="/nightscout-direct">
            <RedirectExternal to={
              `/api/authenticate/nightscout?redirect=https%3A%2F%2Fwww.sensotrend.fi%2Fdashboard%2Fagp${
                requestedLanguage ? `&l=${requestedLanguage}` : ''
              }`}
            />
          </Route>
          <Route path="/nightscout-connect">
            <RedirectExternal to="https://connect.nightscout.fi/" />
          </Route>
          <Route path="/welcome" component={Welcome} />
          <ProtectedRoute path="/registered">
            <Registered />
          </ProtectedRoute>
          <ProtectedRoute path="/kanta">
              <ConnectKantaPHR />
           </ProtectedRoute>
          <ProtectedRoute path="/omakanta">
            <RedirectExternal
              to={`${
                document.location.protocol
              }//${
                document.location.host
              }/api/authenticate/omakanta?${
                requestedLanguage ? `l=${requestedLanguage}&` : ''
              }redirect=${
                encodeURIComponent(`${
                  document.location.href.substring(0, document.location.href.lastIndexOf('/'))
                }/connected${
                  requestedLanguage ? `?l=${requestedLanguage}` : ''
                }`)
              }`}
            />
          </ProtectedRoute>
          <ProtectedRoute path="/connected">
            <Connected />
          </ProtectedRoute>
          <ProtectedRoute path="/terms">
            <AcceptToc />
          </ProtectedRoute>
          <ProtectedRoute path="/transfer">
            <Transfer />
          </ProtectedRoute>
          <Route path="/ready" component={Ready} />

          <Route path="/devices" component={Devices} />
          <Route path="/uploaders" component={Uploaders} />
          <Route path="/uploader" component={Uploader} />
          <Route path="/download/:os" component={Download} />
          <Route path="/upload" component={Upload} />
          <Route path="/view" component={View} />
          <Route path="/coming" component={Connected} />
          <Route path="/eula" component={Eula} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/goodbye-1" component={GoodBye1} />
          <Route path="/goodbye-2" component={GoodBye2} />
          <Route path="/goodbye-3" component={GoodBye3} />
          <Route path="/instructions/sensotrend-connect" component={Instructions} />
          <Route path="/instructions/print" component={InstructionsPrint} />
          <Route path="/instructions/sensotrend-connectSE" component={InstructionsConnectSE} />
          <Route path="/instructions/sensotrend-uploader" component={InstructionsUploader} />
          <Route path="/instructions/sensotrend-uploaderSE" component={InstructionsUploaderSE} />
          <Route path="/instructions/sensotrend-dashboard-intro" component={InstructionsDashboardIntro} />
          <Route path="/instructions/linkit" component={InstructionsLinks} />
          <Route path="/instructions/uploader-abbottLibre" component={InstructionsUploaderAbbottLibre} />
          <Route path="/instructions/uploader-abbottLite" component={InstructionsUploaderAbbottLite} />
          <Route path="/instructions/uploader-abbottNeo" component={InstructionsUploaderAbbottNeo} />
          <Route path="/instructions/uploader-abbottXtra" component={InstructionsUploaderAbbottXtra} />
          <Route path="/instructions/uploader-animas" component={InstructionsUploaderAnimas} />
          <Route path="/instructions/uploader-ascensiaContour" component={InstructionsUploaderAscensiaContour} />
          <Route path="/instructions/uploader-ascensiaContourNextMeter" component={InstructionsUploaderAscensiaContourNextMeter} />
          <Route path="/instructions/uploader-caresens" component={InstructionsUploaderCaresens} />
          <Route path="/instructions/uploader-caresensBt" component={InstructionsUploaderCaresensBt} />
          <Route path="/instructions/uploader-dexcom" component={InstructionsUploaderDexcom} />
          <Route path="/instructions/uploader-insuletOmnipod" component={InstructionsUploaderInsuletOmnipod} />
          <Route path="/instructions/uploader-insuletOmnipodDASH" component={InstructionsUploaderInsuletOmnipodDASH} />
          <Route path="/instructions/uploader-medtronic" component={InstructionsUploaderMedtronic} />
          <Route path="/instructions/uploader-medtronic6xG" component={InstructionsUploaderMedtronic6xG} />
          <Route path="/instructions/uploader-oneTouchVerio" component={InstructionsUploaderOneTouchVerio} />
          <Route path="/instructions/uploader-oneTouchVerioIQ" component={InstructionsUploaderOneTouchVerioIQ} />
          <Route path="/instructions/uploader-oneTouchUltraMini" component={InstructionsUploaderOneTouchUltraMini} />
          <Route path="/instructions/uploader-oneTouchUltra2" component={InstructionsUploaderOneTouchUltra2} />
          <Route path="/instructions/uploader-reliOn" component={InstructionsUploaderReliOn} />
          <Route path="/instructions/uploader-roche" component={InstructionsUploaderRoche} />
          <Route path="/instructions/uploader-tandem" component={InstructionsUploaderTandem} />
          <Route path="/instructions/uploader-trivia" component={InstructionsUploaderTrividia} />
          <Route path="/instructions/uploader-triviaGo" component={InstructionsUploaderTrividiaGo} />
          <Route path={['/instructions/uploader', '/instructions/uploaders']} component={InstructionsUploaders} />
          <Route strict path="/instructions" component={InstructionsLanding} />
          <Route path="/bye" component={Bye} />
          <Route path="/ohjeet" render={({ location }) => <Redirect to={{
            ...location,
            pathname: location.pathname.replace('/ohjeet', '/instructions'),
          }} />
          }/>
          <Route path="/">
            <Redirect to="/goodbye-3" />
          </Route>
        </Switch>
        <ScrollToTop />
      </article>
      <Footer />
    </Router >
  );
}

export default Routes;
