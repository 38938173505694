import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import CallToAction from './CallToAction';

function Connected() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>{t('Connected!')}</h1>
      </header>
      <main>
        <p>{t('Connection to the PHR has been established!')}</p>
        <p>{t('You can now start transferring data to your account.')}</p>
        <CallToAction action="/devices">
          {t('Let\'s continue!')}
        </CallToAction>
      </main>
    </Fragment>
  );
}

export default Connected;
