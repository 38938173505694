import { useEffect, useState } from 'react';

function useDetectPrinting() {
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const printMediaQuery = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');
    const mediaQueryEvent = (mediaQueryList) => setIsPrinting(!!mediaQueryList.matches);

    printMediaQuery.addListener(mediaQueryEvent);
    return () => printMediaQuery.removeListener(mediaQueryEvent);
  });

  return isPrinting;
}

export default useDetectPrinting;
