import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './downloads.css';
import './form.css';

function Upload() {
  const { t } = useTranslation('translations');

  const history = useHistory();

  const [uploaded, setUploaded] = useState(false);

  function processForm(event) {
    event.preventDefault();
    history.push('/view');
  }

  return (
    <Fragment>
      <header>
        <h1>{t('Upload data!')}</h1>
      </header>
      <main id="upload">
        <section>
          <p>
            {t(
              'Once you have installed the Sensotrend Uploader, it is time to use it to transfer data to the Kanta PHR.',
            )}
          </p>
        </section>
        <section>
          {/*
          <figure>
            <img></img>
          </figure>
          */}
          <p>
            {`${t('If you need help with data transfer, read')} `}
            <strong>
              <Link to="/instructions/uploaders">{t('device specific uploading instructions')}</Link>
            </strong>
            .
          </p>
        </section>
        <section>
          {/*
          <figure>
            <img></img>
          </figure>
          */}
        </section>
        <section>
          <form onSubmit={processForm}>
            <p>
              <strong>{t('Once you have uploaded the data, you are able to view it in Kanta PHR.')}</strong>
            </p>
            <div>
              <input
                id="uploaded"
                type="checkbox"
                name="uploaded"
                title={t('Tick this once you are finished uploading your data to Kanta PHR')}
                checked={uploaded}
                onChange={(event) => setUploaded(event.target.checked)}
              />
              <label htmlFor="uploaded">{t('I have transferred data to the PHR')}</label>
            </div>
            <input
              type="submit"
              title={t('Go to Sensotrend Dashboard application')}
              disabled={!uploaded}
              value={t('Onward!')}
            />
          </form>
        </section>
      </main>
    </Fragment>
  );
}

export default Upload;
