import React, { Fragment, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CallToAction from './CallToAction';
import UserSettingsContext from './UserSettingsContext';

function ConnectKantaPHR() {
  const { t } = useTranslation('translations');

  const { userSettings } = useContext(UserSettingsContext);
  const { user = {} } = userSettings;

  // TODO: verify that the connection is valid.
  // If not, prompt for a new connection...
  if (user.services && user.services.some((s) => s.name === 'omakanta')) {
    // Already connected!
    return <Redirect to="/devices" />;
  }

  return (
    <Fragment>
      <header>
        {/* FIXME: find out a way to have "Omatieto&shy;varanto" in the finnish translation */}
        <h1>{t('Kanta PHR')}</h1>
      </header>
      <main>
        {/* FIXME: Is there a better way to deal with sentences with language-specific links in the
            middle?
         */}
        <p>
          {`${t('A part of Kanta services is')} `}
          <a href={t('Link-KantaPHR')} target="_blank" rel="noopener noreferrer">
            {t('To Kanta PHR')}
          </a>
          {` ${t('where you are able to store your wellbeing information')}`}
        </p>
        <p>{t('It is an excellent place for all data needed in diabetes care')}</p>
        <p>{t('You can start using Kanta PHR in My Kanta service')}</p>
        <CallToAction action="/omakanta">{t('Navigate to My Kanta!')}</CallToAction>
      </main>
    </Fragment>
  );
}

export default ConnectKantaPHR;
