import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import CallToAction from './CallToAction';

function View() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>{t('Visualize the data!')}</h1>
      </header>
      <main id="view">
        <section>
          <p>
            <strong>
              {t('You are now able to view your data in Kanta PHR with the Sensotrend Dashboard application.')}
            </strong>
          </p>
          <p>
            {t(
              'Please note that if you only just uploaded your data it may take a few minutes (up to half an hour) for all the data to be stored in Kanta PHR.',
            )}
          </p>
          <p>
            {t(
              'So if you want, feel free to take a short break in the meantime and congratulate yourself on the introduction of the service!',
            )}
          </p>
          <CallToAction action="/dashboard">{t('Visualize the data!')}</CallToAction>
        </section>
      </main>
    </Fragment>
  );
}

export default View;
