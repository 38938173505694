import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Eula() {
  return (
    <Fragment>
      <header>
        <h1 id="kayttoehdot">Käyttöehdot</h1>
        <small>Päivitetty 13.10.2023</small>
      </header>
      <main id="eula">
        <h2 id="tiivistelmä">Käyttöehdot tiivistettyinä</h2>
        <p>Lyhyesti,</p>
        <ul>
          <li>
            <em>
              Emme vaadi sinulta muuta kuin että käytät palveluitamme hyvien tapojen mukaisesti.
            </em>
          </li>
          <li>
            <em>
              Omasta puolestamme teemme parhaamme tarjotaksemme ensiluokkaisia palveluita.
            </em>
          </li>
        </ul>
        <p>
          Alla kerromme tarkemmin palveluistamme ja periaatteista, joiden mukaan niitä
          kehitämme, sekä siitä, kuinka käsittelemme sinun tietojasi.
        </p>
        <h2 id="palvelun-kuvaus">Palvelun kuvaus</h2>
        <p>
          <strong>Sensotrend Connect</strong> -palvelukokonaisuus tukee diabeteksen hoitoa
          kokoamalla tietoja yhteen eri lähteistä, esittämällä havainnollisia yhteenvetoja ja
          helpottamalla tietojen jakamista esimerkiksi terveydenhuollon ja vertaistuen kanssa.
        </p>
        <p>
          Sensotrend Connect -palvelu koostuu seuraavista sovelluksista:
        </p>
        <ul>
          <li>
            <strong>Sensotrend Uploader</strong> on tietokoneelle asennettava sovellus, joka
            auttaa pukamaan tietoja käyttämistäsi laitteista ja siirtämään niitä Sensotrendin
            pilvipalveluun.
          </li>
          <li>
            <strong>Sensotrend Connect</strong> on pilvipalvelu, joka tallentaa ja hallinnoi
            tietoja ja niiden jakamista.
          </li>
          <li>
            <strong>Sensotrend Dashboard</strong> on selainsovellus, joka esittää tietoja eri
            lähteistä diabeteksen hoidon kannalta merkityksellisellä tavalla.
          </li>
        </ul>
        <p>
          Palvelun käyttö ei korvaa tavanomaista terveytesi seurantaa tai terveydenhuollon
          ammattilaisen apua. Palvelun käsittelemistä tiedoista saattaa olla hyötyä havaintojen
          tekemisessä ja hoitotulosten analysoinnissa, mutta tietojen oikeellisuus tulee aina
          tarkistaa alkuperäisistä lähteistä. Älä koskaan tee suuria muutoksia hoitoosi
          ottamatta ensin yhteyttä omaan diabeteshoitajaasi tai -lääkäriisi tai muuhun
          terveydenhuollon ammattilaiseen.
        </p>
        <h2 id="periaatteet">Palvelun kehityksen periaatteet</h2>
        <p>
          Me, Sensotrend Oy, kehitämme näitä palveluita ja tarjoamme niitä käyttöön uskoen ja
          toivoen, että ne helpottavat elämää diabeteksen kanssa.
        </p>
        <p>
          Emme kuitenkaan voi taata, että juuri sinä hyötyisit palveluistamme millään tietyllä
          tavalla. Ihmisillä on eri elämäntilanteita, haasteita ja tavoitteita ja toivomme,
          että jokainen löytäisi itseään parhaiten palvelevat sovellukset. Me toteutamme näitä
          palveluita omista lähtökohdistamme, oman ymmärryksemme ja osaamisemme mukaan. Otamme
          toki oikein mielellämme vastaan palautetta ja ehdotuksia palveluidemme
          kehittämiseksi.
        </p>
        <p>
          Kehitämme palvelua jatkuvasti, hiomme olemassa olevia ominaisuuksia ja lisäämme uusia
          toiminnallisuuksia ja saatamme myös poistaa turhiksi katsomiamme osia
          palvelukokonaisuudesta. Saamallamme palautteella on suuri vaikutus tekemiimme
          päätöksiin.
        </p>
        <h2 id="maksut">Palvelu on potilaille ilmainen</h2>
        <p>
          Tarjoamme palvelumme käyttöösi maksutta. Jos kuitenkin haluat tukea meitä
          taloudellisesti, ole ihmeessä yhteydessä!
        </p>
        <p>
          Terveydenhuollon palveluntarjoajilta laskutamme palvelusta käyttömaksua ja mahdollisesti
          maksuja eri integraatioiden toteuttamisen vaatimista töistä.
        </p>
        <h2 id="yllapito">Ylläpito</h2>
        <p>
          Tuotamme palvelua rajallisilla resursseilla, emmekä voi taata, että se on aina
          saatavilla. Pyrimme kuitenkin siihen, että tietosi ovat käytettävissäsi aina kun
          niitä tarvitset.
        </p>
        <p>
          Palvelimemme sijaitsevat Suomessa tarjottavan palvelun osalta suomalaisen
          internetpalveluntarjoajan konesalissa. Palvelimet on kahdennettu ja toteutettu
          vikasietoisiksi alan parhaiden käytäntöjen mukaisesti.
        </p>
        <h2 id="tietojen-kasittely">Tietojesi käsittely</h2>
        <p>
          <Link to="/privacy">Tietosuojaselosteemme</Link> on voimassa olevan tietosuoja-asetuksen
          (GDPR) mukainen.
        </p>
        <p>
          Tallentamamme tiedot suojaamme parhaamme mukaan ja alan parhaita käytäntöjä
          noudattaen.
        </p>
        <p>
          Emme luovuta emmekä myy tietojasi ilman lupaasi. Tietojesi käyttö saattaa
          tulevaisuudessa olla arvokasta esimerkiksi lääketutkimuksessa tai lääkkeiden tehon
          osoittamisessa, ja pyrimme edistämään mahdollisuuksia käyttää terveys- ja
          hyvinvointitietoja tällaisiin tarkoituksiin. Emme kuitenkaan luovuta sinun tietojasi
          mihinkään tällaiseen tarkoitukseen ilman sinun suostumustasi. Kysymme suostumuksesi
          kuhunkin käyttötarpeeseen erikseen.
        </p>
        <h2 id="virhetilanteet">Virhetilanteet</h2>
        <p>
          Toteuttamamme palvelut koostuvat pääosin ohjelmistokoodista. Ohjelmistokoodi on
          harvoin, jos koskaan, täysin virheetöntä ja kaikki todellisen maailman monimutkaiset
          ilmiöt on mahdotonta mallintaa mukaan ohjelmistokoodiin. On siis hyvinkin
          mahdollista, että joissain tilanteissa palvelumme ei toimi odotetulla tai
          tarkoitetulla tavalla. Sinun on tärkeä ymmärtää virheen mahdollisuus, eikä sinun
          tule tehdä mitään suuria muutoksia hoitoosi ainoastaan meidän palveluidemme antaman
          tiedon perusteella. Arvostamme kovasti kaikkia ilmoituksia palveluidemme käyttöön
          liittyvistä ongelmatilanteista, ne auttavat meitä tekemään tuotteistamme parempia.
        </p>
        <h2 id="lopettaminen">Käytön lopettaminen</h2>
        <p>
          Voit halutessasi milloin tahansa lopettaa palvelun käytön ja poistaa tietosi
          palvelusta.
        </p>
        <p>
          Mikäli me puolestamme havaitsemme käyttäjätiliäsi käytettävän vastoin lakia tai
          asetuksia, voimme myös itse poistaa käyttäjätilisi tai estää sen käytön.
        </p>
      </main>
    </Fragment>
  );
}

export default Eula;
