import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CallToAction from './CallToAction';

function Welcome() {
  const { t } = useTranslation('translations');

  return (
    <Fragment>
      <header>
        <h1>{t('Welcome!')}</h1>
      </header>
      <main>
        <section>
          <p>
            {t(
              'Sensotrend Connect helps you transfer data from blood glucose meters, glucose sensors and insulin pumps to Kanta PHR of Kanta services.',
            )}
          </p>
          <p>
            {t(
              'Once the data is in Kanta PHR, you are able to use your preferred applications to visualize and analyze the data. You can also share your data with your doctor and nurse.',
            )}
          </p>
          <CallToAction action="/kanta">{t("Let's continue!")}</CallToAction>
          <Link
            className="button"
            target="instructions"
            rel="noopener noreferrer"
            to="/instructions/sensotrend-connect"
          >
            {/* FIXME: implement "Yksityis&shy;kohtaiset ohjeet" in finnish translation */}
            {t('Detailed instructions')}
          </Link>
        </section>
      </main>
    </Fragment>
  );
}

export default Welcome;
