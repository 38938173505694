import React, { Fragment } from 'react';

import manufacturerImage from './images/manufacturer.png';
import ceImage from './images/ce.svg';
import eInstruction from './images/e-instruction.png';
import udi from './images/udi.png';
import manufacturingDate from './images/manufacturingDate.png';
import BuildTime from './BuildTime';
import './instructions.css';
import './index.css';

function InstructionsLanding() {
  return (
    <Fragment>
      <header>
        <h1>Säkerhetsinformation - Sensotrend Connect</h1>
      </header>
      <small>Uppdaterad 2022-02-24.</small>
      <main id="instructions">
        <section>
          <h2>Avsedd ändamål av applikationen</h2>
          <p>
            Sensotrend Connect är en applikation som stöder behandlingen av diabetes genom att
            överföra data som samlats in av blodsockermätare, glukossensorer och insulinpumpar och
            liknande enheter från andra applikationer och molntjänster till
            vårdinformationssystem.
          </p>
          <p>
            Applikationen är avsedd för alla användargrupper vars dataöverföring är tillåten och
            möjliggjort av datakällor och vårdinformationssystem som stöds av Sensotrend Connect.
          </p>
        </section>
        <section>
          <h2>Begränsningar</h2>
          <p>
            Applikationen är inte avsedd för överföring av data som används för övervakning av
            hälsotillståndet i realtid.
          </p>
          <p>
            Mätdata som överförs av applikationen är endast lämpliga för att övervaka din hälsa när
            din mätapparat har kalibrerats enligt tillverkarens instruktioner.
          </p>
          <p>
            Användningen av applikationen ersätter inte vården som tillhandahålls av en
            vårdpersonal utan kompletterar den. Om det finns betydande förändringar i din
            vårdbalans, kontakta din vårdgivare.
          </p>
          <p>
            Gör inte betydande förändringar i din behandling baserad endast på data överfört av
            Sensotrend Connect. Innan du fattar ett behandlingsbeslut ska data överfört av
            Sensotrend Connect jämföras med data från den ursprungliga datakällan för att
            säkerställa att hela dataöverföringskedjan har fungerat tillförlitligt.
          </p>
          <p>
            Om allvarliga incidenter inträffar under användningen av applikationen ska de
            rapporteras till vår kundsupport på <a
            href="mailto:support@sensotrend.com">support@sensotrend.com</a> och
            tillsynsmyndigheten.
          </p>
          <h3>Information relaterad till systemkrav</h3>
          <p>
            Sensotrend Connect är en tjänst som inte har specifika systemkrav för
            användarutrustning. Applications användargränssnitt är utformat för att fungera med de
            mest använda webbläsarna. Det är dock inte möjligt att säkerställa att applikationen
            fungerar med alla webbläsarna på marknaden. Om du märker någon felaktig, oväntad elle
            ofullständig aktivitet, vänligen kontakta vår kundsupport på <a
            href="mailto:support@sensotrend.com">support@sensotrend.com</a> så att vi kan lösa och,
            om möjligt, rätta till problemet.
          </p>
          <p>
            Använd inte applikationen för att övervaka din hälso- och vårdbalans om den verkar
            fungera felaktigt.
          </p>
        </section>
        <aside id="label">
          <h2>Produktinformation</h2>
          <p>Sensotrend Connect v{process.env.REACT_APP_VERSION}</p>
          <section id="ce">
            <img className="labeling" src={ceImage} alt="CE" />
            <p>
              Sensotrend Connect är en medicinteknisk produkt av klass I. Se <a href={
                `${
                  navigator.userAgent === 'ReactSnap'
                    ? 'https://www.sensotrend.fi/connect'
                    : process.env.PUBLIC_URL
                }/DECO-T5-DC-MDD_EU_Declaration_of_Conformity_-_Sensotrend_Connect_-_signed.pdf`
              }
              rel="noopener noreferrer" target="_blank">försäkran om överensstämmelse</a>.
            </p>
          </section>
          <section id="eInstruction">
          <img alt="Elektronisk instruktion" title="Elektronisk instruktion" src={eInstruction} />
            <img
              className="labeling"
              src={eInstruction}
              alt="Elektronisk instruktion"
            />
            {
            /*
            This clause is for EU regulation for electronic instructions (2021/2226), article 6,
            clause 1 and 3d.
            */
            }
            <p>
              Denna bruksanvisning tillhandahålls endast i elektronisk form. Du kan begära en
              pappersversion från <a
              href="mailto:support@sensotrend.com">support@sensotrend.com</a>. Pappersversionen
              kommer att levereras inom 7 kalenderdagar efter mottagandet av begäran.
            </p>
          </section>
          <section id="device">
            <img className="labeling" src={udi} alt="UDI" />
            <p>D-FIMF000000500SC000001F9</p>
          </section>
          <section id="date">
          <img alt="Tillverkningsdatum" title="Tillverkningsdatum" src={manufacturingDate} />
          {BuildTime}
          </section>
          <section id="manufacturer">
            <img className="labeling" src={manufacturerImage} alt="Tillverkare" />
            <address>
              Sensotrend Oy<br />
              Tampellan esplanadi 19 A 55<br />
              33180 Tammerfors<br />
              Finland<br />
              <br />
              <a href="mailto:support@sensotrend.com">support@sensotrend.com</a><br />
            </address>
          </section>
        </aside>
      </main>
    </Fragment >
  );
}

export default InstructionsLanding;
