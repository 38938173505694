import imageDe from '../images/de/sensotrend_connect_install_uploader1.png';
import imageEn from '../images/en/sensotrend_connect_install_uploader1.png';
import imageEs from '../images/es/sensotrend_connect_install_uploader1.png';
import imageFi from '../images/fi/sensotrend_connect_install_uploader1.png';
import imageFr from '../images/fr/sensotrend_connect_install_uploader1.png';
import imageIt from '../images/it/sensotrend_connect_install_uploader1.png';
import imageNo from '../images/no/sensotrend_connect_install_uploader1.png';
import imageSv from '../images/sv/sensotrend_connect_install_uploader1.png';

export const installUploader1Image = {
  de() {
    return imageDe;
  },
  en() {
    return imageEn;
  },
  es() {
    return imageEs;
  },
  fi() {
    return imageFi;
  },
  fr() {
    return imageFr;
  },
  it() {
    return imageIt;
  },
  no() {
    return imageNo;
  },
  sv() {
    return imageSv;
  },
};
