import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const hashedElement = document.getElementById(hash.substring(1));
      hashedElement ? hashedElement.scrollIntoView() : window.scrollTo(0, 0);
    } else {
      window.scrollTo && window.scrollTo(0, 0);
    }
  }, [hash, pathname]);

  return null;
}

export default ScrollToTop;
