import React, { Fragment } from 'react';

function Privacy() {
  return (
    <Fragment>
      <header>
        <h1 id="tietosuoja">Tietosuoja</h1>
        <small>Päivitetty 19.11.2023</small>
      </header>
      <main id="privacy">
        <p>
          Tällä sivulla kerromme miten ja miksi keräämme sinua koskevia tietoja Sensotrend
          Connect-palvelun käyttäjänä ja kuinka huolehdimme yksityisyydestäsi.
        </p>
        <h2 id="mita-tietoja-keraamme">Mitä tietoja keräämme?</h2>
        <p>
          Keräämme sinusta ainoastaan ne tiedot, jotka palvelun toteuttamisen kannalta ovat
          olennaisia.
        </p>
        <p>
          Keräämme seuraavia tietoja:
        </p>
        <ul>
          <li>
            Itse ilmoittamasi nimesi, jotta voimme esittää sen käyttöliittymän osana ja sinulle
            lähettämissämme viesteissä. Tämä auttaa varmistamaan, että joka tilanteessa katsotaan
            oikean henkilön tietoja.
          </li>
          <li>
            Sähköpostiosoitteesi, jotta voimme kertoa sinulle palvelun käyttöön liittyvistä
            poikkeustilanteista sekä muutoksista käyttöehtoihin tai tähän
            tietosuojaselosteeseen.
          </li>
          <li>
            Internet-palvelimemme tekniset lokit, jotta voimme seurata palvelun toiminnan
            luotettavuutta ja selvittää mahdollisten virhetilanteiden syitä. Tähän lokiin tallentuu
            esimerkiksi IP-osoitteesi.
          </li>
          <li>
            Käyttöavaimia (access token), joiden avulla palvelu pääsee noutamaan tietoja eri
            tietolähteistä kuten Google Fit, Dropbox ja muut pilvipalvelut. Nämä käyttöavaimet
            luodaan, kun liität palveluun tietolähteitä.
          </li>
          <li>
            Tietoja, jotka itse siirrät palveluun esimerkiksi Sensotrend Uploader
            -sovelluksella tai joita pyydät meitä kopioimaan pilvipalveluista tai muista lähteistä.
          </li>
          <li>
            Mahdollinen vikatilanneilmoituslupa, mikäli olet sen erikseen antanut, sekä
            luvan myöntämisen ajankohta, jotta tiedämme haluatko, että ilmoitamme Sinulle
            palvelun vikatilanteista.
          </li>
          <li>
            Mahdollinen viestintälupa, mikäli olet sen erikseen antanut, sekä luvan
            myöntämisen ajankohta, jotta tiedämme haluatko, että kerromme sinulle palvelun
            kehittymisestä ja mahdollisuuksista osallistua palvelun jatkokehitykseen.
          </li>
          <li>
            Mahdolliset muut erikseen antamasi suostumuksen perusteella kerättävät
            tiedot.
          </li>
        </ul>
        <h2 id="miksi-keraamme-tietoja">Miksi keräämme tietoja?</h2>
        <p>
          Keräämme tietoa sinulle arvokkaan palvelun toteuttamiseksi sekä kehittääksemme
          palveluitamme paremmin diabeetikoiden, heidän läheistensä ja heitä hoitavien
          ammattilaisten tarpeita vastaaviksi.
        </p>
        <h2 id="miten-keraamme-ja-kasittelemme-tietoja">
          Miten keräämme ja käsittelemme tietoja?
        </h2>
        <p>
          Otamme yksityisyytesi suojan vakavasti ja keräämme sekä käsittelemme tietojasi
          ainoastaan, kun palvelun toteuttaminen sitä vaatii.
        </p>
        <p>
          Osa tiedoista kertyy automaattisesti teknisiin lokitiedostoihin, osa tallennetaan
          kun itse täytät palveluun tietoja, esimerkiksi nimesi tai sähköpostiosoitteesi.
        </p>
        <p>
          Tietoihin pääsee käsiksi vain tarkkaan rajattu joukko ihmisiä, kulloinkin vain tietyn
          tarkkaan määritetyn tarpeen vuoksi.
        </p>
        <p>Tietojasi ei käytetä automatisoituun päätöksentekoon eikä profilointiin.</p>
        <h2 id="automaattinen-tietojen-keraaminen">Automaattinen tietojen kerääminen</h2>
        <p>
          Tiedot, joita keräämme automaattisesti:
        </p>
        <ul>
          <li>Internet-palvelimen tekniset lokit</li>
          <li>Käyttöavaimet (access token)</li>
        </ul>
        <h2 id="miten-varmistamme-henkilotiedon-tietosuojan">
          Miten varmistamme henkilötiedon tietosuojan?
        </h2>
        <p>
          Noudatamme EU:n yleiseen tietosuoja-asetuksen (GDPR) vaatimia asianmukaisia
          hallinnollisia ja tietoteknisiä toimia henkilötietojesi suojaamiseksi. Jokainen
          IT-järjestelmämme arvioidaan tietoturvan näkökulmasta ja tietoturvakäytäntöjämme
          kehitetään jatkuvasti.
        </p>
        <h2 id="oikeutesi-palvelun-kayttajana">Oikeutesi palvelun käyttäjänä</h2>
        <p>
          Sinulla on kaikki EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet, kuten oikeus
          tietojesi tarkastamiseen ja mahdollisten virheiden oikaisuun, tietojen lataamiseen
          itsellesi sekä tietojesi poistamiseen. Tietojen tallentaminen onnistuu omatoimisesti
          palvelun kautta. Tietojen poistaminen tapahtuu lopettamalla palvelun käyttö palvelun
          käyttöliittymän kautta. Tässä tilanteessa sinusta tallennetut tiedot poistetaan
          teknisiä lokeja lukuun ottamatta automaattisesti ja välittömästi.
        </p>
        <p>
          Oikeuksiasi koskevat pyynnöt pyydämme lähettämään tietosuojavastaavallemme, jonka
          yhteystiedot löydät alta.
        </p>
        <h2 id="yhteystiedot">Yhteystiedot</h2>
        <address>
          Sensotrend Oy<br />
          Tampellan esplanadi 19 A 55<br />
          33180 Tampere.
        </address>
        <p>
          Tietosuojavastaava Mikael Rinnetmäki, <a
            href="mailto:privacy@sensotrend.com">privacy@sensotrend.com</a>.
        </p>
        <h2 id="muutoksetjapaivitykset">Muutokset ja päivitykset</h2>
        <p>
          Päivitämme tätä tietosuojalauseketta tarpeen mukaan ja kerromme parhaamme mukaan
          sinulle tehdyistä muutoksista - etenkin, jos ne jollain lailla vaikuttavat sinun
          oikeuksiisi tai sinun tietojesi käsittelyyn.
        </p>
      </main>
    </Fragment>
  );
}

export default Privacy;
