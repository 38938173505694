import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BuildTime from './BuildTime';
import ce from './images/ce.svg';
import eInstruction from './images/e-instruction.png';
import manufacturer from './images/manufacturer.png';
import manufacturingDate from './images/manufacturingDate.png';
import udi from './images/udi.png';
import './instructions.css';
import './index.css';

function UploaderInstructions() {
  const { t } = useTranslation('translations');
  return (
    <Fragment>
      <header>
        <h1 id="SensotrendUploader">Sensotrend Uploader</h1>
      </header>
      <main id="instructions" className="textPage">
        <section id="intendeduse">
          {/* This section includes the "intended target groups with clear indications".
              Ref.
                - CLIN-T2-PL Clinical Evaluation Plan, clause 4.1, and
                - MDR annex XIV, clause 1, 3rd bullet, and annex I, clause 23.4, subclause b).
          */}
          <h2>{t('Safety Information')}</h2>
          {/* Language reguirements are coming from finnish law 629/2010, 12 § */}
          <small><Link to={t('Link-LocalAdditionalSafetyInfoRequired-Uploader')}>{t('LinkText-LocalAdditionalSafetyInfoRequired-Uploader')}</Link></small>
          <h3>{t('Indications for Use')}</h3>
          <p>
            {t('Sensotrend Uploader is a PC-based application that supports the treatment of diabetes by transferring data from blood glucose meters and sensors, as well as insulin pumps and similar devices, to cloud services.')}
          </p>
          <p>
            {t('The Sensotrend Uploader is designed for diabetics responsible for their care. However, we do not want to limit the use of the application to this group of users only. For other user groups, we consider the party responsible for care to be also responsible for using the Sensotrend Uploader.')}
          </p>
        </section>
        <section id="contraindications">
          {/* This section includes the "contra-indications".
              Ref.
                - CLIN-T2-PL Clinical Evaluation Plan, clause 4.1, and
                - MDR annex XIV, clause 1, 3rd bullet, and annex I, clause 23.4, subclause b).
          */}
          <h3>{t('Contraindications')}</h3>
          <p>
            {/* This clause is for MDR, annex I, clause 23.4, subclause w). */}
            {t('The use of the application does not replace the care provided by a healthcare professional, but complements it. If there are significant changes in your glucose balance, contact your healthcare provider.')}
          </p>
          <p>
            {t('Do not make significant changes to your treatment based solely on the data transferred by the Sensotrend Uploader. Before making a treatment decision, the data transferred by the Sensotrend Uploader should be compared with the data from the original data source to ensure that the entire data transfer chain has operated reliably.')}
          </p>
          <p>
            {/* This clause is for MDR, annex I, clause 23.4 subclause z). */}
            {t('If you encounter serious incidents while using the application, you should notify our customer support at')} <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a> {t('and the competent authority.')}
          </p>
          <h3>{t('Information related to System Requirements')}</h3>
          <p>
            {/* This clause is for MDR, annex I, clause 23.4, subclause ab). */}
            {t('Sensotrend Uploader is designed to run on modern Windows, Mac and Linux computers. However, it is not possible to ensure the operation of all configurations on the market individually. If you notice any incorrect, unexpected, or incomplete activity that may be due to the hardware or software you are using, please contact our customer support by')} <a href="mailto:support@sensotrend.com">{t('support@sensotrend.com')}</a>{t(' so that we can resolve and, to the best of our ability, correct the problem.')}
          </p>
          <p>
            {t('Do not use the application to transfer data used to monitor your health and balance of care if it appears to be malfunctioning.')}
          </p>
        </section>
        <section>
          <h2>{t('Device-specific data transfer instructions')}</h2>
          <ul>
            <li><Link to="/instructions/uploader-abbottLibre">Abbott FreeStyle Libre</Link></li>
            <li><Link to="/instructions/uploader-abbottLite">Abbott FreeStyle Lite &amp; Freedom Lite</Link></li>
            <li><Link to="/instructions/uploader-abbottNeo">Abbott FreeStyle Precision/Optium Neo </Link></li>
            <li><Link to="/instructions/uploader-abbottXtra">Abbott Precision Xtra </Link></li>
            <li><Link to="/instructions/uploader-animas">Animas </Link></li>
            <li><Link to="/instructions/uploader-ascensiaContourNextMeter">Ascensia (Bayer) Contour Next </Link></li>
            <li><Link to="/instructions/uploader-ascensiaContour">Ascensia (Bayer) Contour Next EZ, Contour, Contour Link {t('or')} Contour Plus </Link></li>
            <li><Link to="/instructions/uploader-caresens">CareSens N Premier &amp; Dual </Link></li>
            <li><Link to="/instructions/uploader-caresensBt">CareSens N Premier &amp; Dual ({t('Using Bluetooth')}) </Link></li>
            <li><Link to="/instructions/uploader-dexcom">Dexcom </Link></li>
            <li><Link to="/instructions/uploader-insuletOmnipod">Insulet OmniPod </Link></li>
            <li><Link to="/instructions/uploader-insuletOmnipodDASH">Insulet OmniPod DASH </Link></li>
            <li><Link to="/instructions/uploader-medtronic">Medtronic 523, 723, Veo {t('or')} 530G ({t('Using Contour Next Link')}) </Link></li>
            <li><Link to="/instructions/uploader-medtronic6xG">Medtronic 630G, 640G, 670G ({t('Using Contour Next Link 2.4')}) </Link></li>
            <li><Link to="/instructions/uploader-oneTouchVerio">OneTouch Verio, Verio Flex {t('or')} Verio Reflect </Link></li>
            <li><Link to="/instructions/uploader-oneTouchVerioIQ">OneTouch VerioIQ </Link></li>
            <li><Link to="/instructions/uploader-oneTouchUltraMini">OneTouch UltraMini </Link></li>
            <li><Link to="/instructions/uploader-oneTouchUltra2">OneTouch Ultra 2 </Link></li>
            <li><Link to="/instructions/uploader-reliOn">ReliOn Premier (BLU, Voice &amp; Classic) </Link></li>
            <li><Link to="/instructions/uploader-roche">Roche Accu-Chek Aviva Connect, Guide &amp; Guide Me </Link></li>
            <li><Link to="/instructions/uploader-tandem">Tandem </Link></li>
            <li><Link to="/instructions/uploader-trivia">Trividia Health True Metrix {t('or')} True Metrix Air </Link></li>
            <li><Link to="/instructions/uploader-triviaGo">Trividia Health True Metrix Go </Link></li>
          </ul>
        </section>
        <aside id="label">
          {/* This section includes the label.
            Ref. MDR annex XIV, clause 1, 3rd bullet, and annex I, clause 23.2.
          */}
          <h2>{t('Product Information')}</h2>
          {/* This clause is for MDR, annex I, clause 23.2, subclauses a) and b). */}
          <p>Sensotrend Uploader</p>
          {/* This clause is for MDR, annex I, clause 23.2, subclause q). */}
          <section id="ce">
            <img alt="CE" src={ce} />
            <p>
              {t('Sensotrend Uploader is a class I medical device.')}<br />
              {t('See ')}<a
                href={`${navigator.userAgent === 'ReactSnap'
                  ? 'https://www.sensotrend.fi/connect'
                  : process.env.PUBLIC_URL
                }/DECO-T5-DC-MDD_EU_Declaration_of_Conformity_-_Sensotrend_Uploader_-_signed.pdf`}
                rel="noopener noreferrer" target="_blank"
              >{t('Declaration of Conformity')}</a>.
            </p>
          </section>
          <section id="eInstruction">
            <img alt={t('Electronic instruction')} title={t('Electronic instruction')} src={eInstruction} />
            {/* This clause is for EU regulation for electronic instructions (2021/2226),
                article 6, clause 1 and 3d.
              */
            }
            <p>
              {t('This instruction for use is supplied in electronic form only. You can request a paper version from ')}
              <a href={`mailto:${t('support@sensotrend.com')}`}>{t('support@sensotrend.com')}</a>
              {t('. The paper version will be delivered within 7 calendar days of receiving the request.')}
            </p>
          </section>
          <section id="device">
              {/* This clause is for MDR, annex I, clause 23.2, subclause h). */}
            <img alt={t('UDI')} title={t('UDI')} src={udi} />
            <p>D-FIMF000000500SU000001QT</p>
          </section>
          <section id="date">
              {/* Date of manufacture is for MDR, annex I, clause 23.2, subclause j).
                Date format is specified in ISO 1041:2008 & A1:2013, clause 4.4.
              */}
            <img alt={t('Date of manufacture')} title={t('Date of manufacture')} src={manufacturingDate} />
            {BuildTime}
          </section>
          <section id="manufacturer">
            <img alt={t('Manufacturer')} title={t('Manufacturer')} src={manufacturer} />
            {/* This clause is for MDR, annex I, clause 23.2, subclause c). */}
            <address>
              {/* Address is specified in ISO 1041:2008 & A1:2013, clause 5.1.2. */}
              Sensotrend Oy<br />
              Tampellan esplanadi 19 A 55<br />
              33180 Tampere{t(', Finland')}<br />
              <a href={`mailto:${t('support@sensotrend.com')}`}>{t('support@sensotrend.com')}</a>
            </address>
          </section>
        </aside>
        <footer>
          {/* The date is for MDR, annex I, clause 23.4, subclause y).
          Date format is specified in ISO 1041:2008 & A1:2013, clause 4.4.
          */}
          <small>2022-02-24</small>
        </footer>
      </main>
    </Fragment>
  );
}

export default UploaderInstructions;
