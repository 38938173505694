import React, {
  Fragment, useContext, useEffect, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CallToAction from './CallToAction';
import UserSettingsContext from './UserSettingsContext';
import { devices as categories, populateDevices } from './Devices';
import './downloads.css';

const supportFutureInfo = true;

/*
const categoryLookup = [ ...categories].sort((a, b) => a.devices.length < b.devices.length);
function getCategory(device) {
  let category;
  categoryLookup.some(c => c.devices.some((d) => {
    if (d.id === device.id) {
      category = c.name;
      return true;
    }
    return false;
  }));
  return category;
}
*/

function getShortName(device) {
  return device.title.replace(/ \(.+\)/, '');
}

/*
function categorize(devices) {
  const categorized = categories.reduce((o, c) => {
    o[c.name] = [];
    return o;
  }, {});
  devices.forEach((d) => {
    const category = getCategory(d);
    const shortName = getShortName(d);
    categorized[category].push(shortName);
  });
  return categorized;
}

const currentlySupportedUploaderDevices = Object.keys(categories).reduce((o, k) => {
  o[k] = categories[k].filter(d => d.uploaderDeviceName).map(d => d.id);
  return o;
}, {});

const currentlySupportedUploaderDevices = Object.keys(categories).reduce((o, k) => {
  console.log('Iterating key', k);
  o[k] = categories[k].filter(d => d.uploaderDeviceName).map(d => d.id);
  return o;
}, {});

*/

function Uploaders() {
  const { t } = useTranslation('translations');

  function formatPlural(arr, j = t('listLastSeparator')) {
    let joined = arr.join(', ');
    if (arr.length > 1) {
      joined = joined.replace(/(, )(?!.*\1)/, j);
    }
    return joined;
  }

  function formatFriendlyFrom(glucometers, cgms, pumps) {
    const g = glucometers.length
      ? `${
        t('glucometers', {
          count: glucometers.length, list: formatPlural(glucometers.map(getShortName)),
        })
      }`
      : undefined;
    const c = cgms.length
      ? `${
        t('cgms', { count: cgms.length, list: formatPlural(cgms.map(getShortName)) })
      }`
      : undefined;
    const p = pumps.length
      ? `${
        t('pumps', { count: pumps.length, list: formatPlural(pumps.map(getShortName)) })
      }`
      : undefined;
    const all = formatPlural([g, c, p].filter((e) => !!e), t('listLastSeparator2'));
    // Let's make sure the first letter is capitalized, we cannot guarantee that from localizations
    return all.length > 0 ? `${all[0].toLocaleUpperCase()}${all.substring(1)}` : all;
  }

  const { userSettings } = useContext(UserSettingsContext);
  const { preselectedDevices = '', selectedDevices = categories } = userSettings;

  const devices = selectedDevices !== categories
    ? selectedDevices
    : populateDevices(preselectedDevices);

  const supportedUploaderDevicesSelected = useMemo(() => {
    const selectedCategories = Object.values(populateDevices(preselectedDevices));
    // console.log({ selectedCategories });
    return selectedCategories.reduce(
      (found, arr) => found || arr.some((d) => !!d?.uploaderDeviceName),
      false,
    );
  }, [preselectedDevices]);

  useEffect(() => {
    // Post selected devices to be part of the user profile
  });

  const uploaderDevices = Object.keys(categories).reduce((o, k) => ({
    ...o,
    [k]: Object.values(categories[k]).filter((d) => !!d.uploaderDeviceName),
  }), {});

  const { glucometers, cgms, pumps } = devices;

  return (
    <Fragment>
      <header>
        <h1>{t('Uploaders')}</h1>
      </header>
      <main id="uploaders">
        {supportedUploaderDevicesSelected
          ? (
            <h2>
              {t('The following applications are suitable for the devices you have selected')}:
            </h2>
          )
          : (
            <Fragment>
              <h2>{t('There are many options for transferring data to Kanta PHR')}</h2>
              <p>
                {t('You can find out the ways that work best for you')} <Link
                to="/devices">{t('by choosing the devices you use')}</Link>.
              </p>
            </Fragment>
          )
        }
            <section>
              {((supportedUploaderDevicesSelected) && (glucometers.length < 3) && (cgms.length < 3)
                && (pumps.length < 3))
                ? (
                  <p>
                    {t('From ')}
                    <strong>{formatFriendlyFrom(glucometers, cgms, pumps)}</strong>
                    {t(' data can be uploaded by using the Sensotrend Uploader application installed on your computer.')}
                  </p>
                )
                : (
                  <Fragment>
                    <h4>{t('Sensotrend Uploader supports data transfer from these devices')}:</h4>
                    <h5>{t('Blood glucose meters')}:</h5>
                    {
                      <ul>
                        {uploaderDevices.glucometers.map((device) => (
                          <li key={device.name}><strong>{getShortName(device)}</strong></li>
                        ))}
                      </ul>
                    }
                    <h5>{t('Continuous glucose monitors')}:</h5>
                    {
                      <ul>
                        {uploaderDevices.cgms.map((device) => (
                          <li key={device.name}><strong>{getShortName(device)}</strong></li>
                        ))}
                      </ul>
                    }
                    <h5>{t('Insulin pumps')}:</h5>
                    {
                      <ul>
                        {uploaderDevices.pumps.map((device) => (
                          <li key={device.name}><strong>{getShortName(device)}</strong></li>
                        ))}
                      </ul>
                    }
                  </Fragment>
                )
              }
              <CallToAction action="/uploader">
                {t('Download Sensotrend Uploader!')}
              </CallToAction>
              {supportFutureInfo
                && (!supportedUploaderDevicesSelected
                  || Object.values(selectedDevices.glucometers)
                    .some((device) => device.name === 'caresensNPop')
                )
                ? (
                  <Fragment>
                    <p className="future info">
                      <strong>
                        {t('Support for the CareSens N POP blood glucose meter is coming to the Sensotrend Uploader application.')}
                      </strong>
                    </p>
                    {/* TODO: email message translation */}
                    <p className="future info">
                      {t('If you want to be notified as soon as it is possible to upload the data')}
                      , <a
                      href={`mailto:info@sensotrend.com?subject=${
                        encodeURIComponent('CareSens N POP')
                      }&body=${
                        encodeURIComponent(
                          'Hei Sensotrend,\n\nKertokaa minulle, kun Sensotrend Uploader tukee CareSens N POP -mittaria.\n\nKiitokset!\n',
                        )
                      }`}>{t('let us know')}</a>{t(' and we\'ll keep you posted!')}
                    </p>
                  </Fragment>
                )
                : undefined
              }
              {(Object.values(selectedDevices.glucometers)
                .some((device) => device.name.toLowerCase().indexOf('caresens') >= 0))
                ? (
                  <Fragment>
                    {/* FIXME: different links for english translations? */}
                    <p className="future info">
                      {t('To transfer data from CareSens N POP blood glucose meter you need a compatible')} <a
                      href="http://www.medcase.fi/diabetes/tuotteet/?id=caresens_smartlog_kaapeli"
                      target="_blank" rel="noopener noreferrer">{t('data transfer cable')}</a>,
                      {` ${t('ask for one from your care team.')}`}
                    </p>
                    <p className="future info">
                      {t('Alternatively, if available, you can also use a Bluetooth-enabled meter')} (<a
                      href="http://medcase.fi/diabetes/tuotteet/?id=caresens_n_premier_verensokerimittari"
                      target="_blank" rel="noopener noreferrer">CareSens Premier</a> {t('or')} <a
                      href="http://medcase.fi/diabetes/tuotteet/?id=caresens_dual_verensokerimittari_ketoainemittari"
                      target="_blank" rel="noopener noreferrer">CareSens Dual</a>) {t('. Ask for these meters from your care team!')}
                    </p>
                  </Fragment>
                )
                : undefined
              }
              {supportFutureInfo
                && (!supportedUploaderDevicesSelected
                  || Object.values(selectedDevices.pumps)
                    .some((device) => device.title.indexOf('Roche') >= 0)
                )
                ? (
                  <Fragment>
                    <p className="info">
                      <strong>
                        {t('Support for Accu-Chek\'s pumps is coming to the Sensotrend Uploader application.')}
                      </strong>
                    </p>
                    {/* TODO: email message translation */}
                    <p className="info">
                      {t('If you want to be notified as soon as it is possible to upload the data')}
                      , <a
                      href={`mailto:info@sensotrend.com?subject=${
                        encodeURIComponent('Accu-Chek-pumput')
                      }&body=${
                        encodeURIComponent(
                          'Hei Sensotrend,\n\nKertokaa minulle, kun Sensotrend Uploader tukee tiedonsiirtoa Accu-Chek-pumpuista.\n\nKiitokset!\n',
                        )
                      }`}>{t('let us know')}</a>{t(' and we\'ll keep you posted!')}
                    </p>
                  </Fragment>
                )
                : undefined
              }
              {supportFutureInfo
                && (!supportedUploaderDevicesSelected
                  || Object.values(selectedDevices.cgms)
                    .some((device) => device.title.indexOf('Eversense') >= 0)
                )
                ? (
                  <Fragment>
                    <p className="info">
                      <strong>
                        {t(
                          'We are considering implementing support for transferring data from the Eversense sensor to Kanta PHR.',
                        )}
                      </strong>
                    </p>
                    <p className="info">
                      {t('If you want to know more')}, <a
                      href={`mailto:info@sensotrend.com?subject=${
                        encodeURIComponent('Accu-Chek-pumput')
                      }&body=${
                        encodeURIComponent(
                          'Hei Sensotrend,\n\nKertokaa minulle, kun Sensotrend Uploader tukee tiedonsiirtoa Eversense-sensorista.\n\nKiitokset!\n',
                        )
                      }`}>{t('let us know')}</a>{t(' and we\'ll keep you posted!')}
                    </p>
                  </Fragment>
                )
                : undefined
              }
            </section>
        {!supportedUploaderDevicesSelected
          || Object.values(selectedDevices.services)
            .some((device) => device.title.indexOf('Nightscout') >= 0)
          ? (
            <Fragment>
              <section>
                <p>
                  {t('If you use Nightscout apps, you can link your Nightscout service directly as a data source for Sensotrend Dashboard application.')}
                </p>
                <CallToAction action="/nightscout-direct">
                  {t('Link Nightscout as a data source!')}
                </CallToAction>
                <p>
                  {t('If you\'re interested in Nightscout apps, you can also use Nightscout Connect service on Kanta PHR.')}
                </p>
                <CallToAction action='/nightscout-connect'>
                  {t('Start using Nightscout Connect!')}
                </CallToAction>
              </section>
            </Fragment>
          )
          : undefined
        }
        {supportFutureInfo
          && (!supportedUploaderDevicesSelected
            || Object.values(selectedDevices.services)
              .some((device) => device.title.indexOf('Clarity') >= 0)
          )
          ? (
            <section>
              <p className="future info">
                {t(
                  'We are currently working with a link to Dexcom\'s Clarity cloud service to enable automatic transfer of data from there to Kanta PHR.',
                )}
              </p>
              {/* TODO: email message translation */}
              <p className="future info">
                {t('If you want to be notified as soon as it is possible to link the nameOfService service', { nameOfService: 'Clarity' })}, <a
                href={`mailto:info@sensotrend.com?subject=${
                  encodeURIComponent('Dexcom Clarity')
                }&body=${
                  encodeURIComponent(
                    'Hei Sensotrend,\n\nKertokaa minulle, kun Sensotrend Connect tukee Dexcomin Clarity-pilvipalvelua.\n\nKiitokset!\n',
                  )
                }`}>{t('let us know')}</a>{t(' and we\'ll keep you posted!')}
              </p>
            </section>
          )
          : undefined
        }
        {supportFutureInfo
          && (!supportedUploaderDevicesSelected
            || Object.values(selectedDevices.services)
              .some((device) => device.title.indexOf('Medtronic') >= 0)
          )
          ? (
            <section>
              <p className="future info">
                {t(
                  'We are currently working with a link to Medtronic\'s CareLink cloud service to enable automatic transfer of data from there to Kanta PHR.',
                )}
              </p>
              {/* TODO: email message translation */}
              <p className="future info">
              {t('If you want to be notified as soon as it is possible to link the nameOfService service', { nameOfService: 'CareLink' })}, <a
                href={`mailto:info@sensotrend.com?subject=${
                  encodeURIComponent('Medtronic CareLink -pilvipalvelu')
                }&body=${
                  encodeURIComponent(
                    'Hei Sensotrend,\n\nKertokaa minulle, kun Sensotrend Connect tukee Medtronicin CareLink-pilvipalvelua.\n\nKiitokset!\n',
                  )
                }`}>{t('let us know')}</a>{t(' and we\'ll keep you posted!')}
              </p>
            </section>
          )
          : undefined
        }
        {supportFutureInfo
          && (!supportedUploaderDevicesSelected
            || Object.values(selectedDevices.services)
              .some((device) => device.title.indexOf('Abbott') >= 0)
          )
          ? (
            <section>
              <p className="future info">
                {t(
                  'If you use your smartphone to read the Libre sensor, or if you upload reader data to the LibreView cloud service, in the future you are able to transfer the data directly from the cloud service.',
                )}
              </p>
              <p className="future info">
                {t(
                  'We are currently working with a link to Abbott\'s LibreView cloud service to enable automatic transfer of data from there to Kanta PHR.',
                )}
              </p>
              {/* TODO: email message translation */}
              <p className="future info">
              {t('If you want to be notified as soon as it is possible to link the nameOfService service', { nameOfService: 'LibreView' })}, <a
                href={`mailto:info@sensotrend.com?subject=${
                  encodeURIComponent('LibreView-pilvipalvelu')
                }&body=${
                  encodeURIComponent(
                    'Hei Sensotrend,\n\nKertokaa minulle, kun Sensotrend Connect tukee LibreView-pilvipalvelua.\n\nKiitokset!\n',
                  )
                }`}>{t('let us know')}</a>{t(' and we\'ll keep you posted!')}
              </p>
            </section>
          )
          : undefined
        }
        {supportFutureInfo
          && (!supportedUploaderDevicesSelected
            || Object.values(selectedDevices.services)
              .some((device) => device.title.indexOf('Tidepool') >= 0)
          )
          ? (
            <section>
              <p className="future info">
                {t(
                  'We are currently considering implementing a link to Tidepool\'s cloud service to enable automatic transfer of data from there to Kanta PHR.',
                )}
              </p>
              {/* TODO: email message translation */}
              <p className="future info">
                {t('If you are interested in this possibility')}, <a
                href={`mailto:info@sensotrend.com?subject=${
                  encodeURIComponent('Tidepool-pilvipalvelu')
                }&body=${
                  encodeURIComponent(
                    'Hei Sensotrend,\n\nMinua kiinnostaisi kuulla suunnitelmista Tidepool-palvelun liittämisestä Omatietovarantoon. Kerrotteko lisää asiasta?\n\nKiitokset!\n',
                  )
                }`}>{t('please be in touch')}</a>{t('and we\'ll discuss the topic in more detail!')}
              </p>
            </section>
          )
          : undefined
        }
        <p>
          {t(
            'Once you have transferred data to Kanta PHR, you are able to visualize it with Sensotrend Dashboard application, for example.',
          )}
        </p>
        <Link className="button" to="/dashboard">
          {t('Visualize the data!')}
        </Link>
      </main>
    </Fragment>
  );
}

export default Uploaders;
