import React, { Suspense } from 'react';

import Routes from './Routes';
import { UserSettingsContextProvider } from './UserSettingsContext';
import './i18n';

function App() {
  return (
    <Suspense fallback={<div>...</div>}>
      <UserSettingsContextProvider>
        <Routes />
      </UserSettingsContextProvider>
    </Suspense>
  );
}

export default App;
