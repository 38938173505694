import { useTranslation } from 'react-i18next';

function Ready() {
  const { t } = useTranslation('');

  return (
    <section id="transfer">
      <h1>{t('it-is-ready')}</h1>
      <p>
      {t('your-data-will-now-be-transferred')} </p>
      <p>
      {t('we-would-be-happy-to-hear-your-thoughts')} <a
        href="mailto:info@sensotrend.com">info@sensotrend.com</a> {t('or-with-the-feedback-form')}
      </p>
      <a className="button primary btn" href="/dashboard/feedback">{t('send-a-message')}</a>
    </section>
  );
}

export default Ready;
